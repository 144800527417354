

import { DatePipe } from '@angular/common';
import { noUndefined } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { async } from '@angular/core/testing';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';
import Swal from'sweetalert2';
import * as alertifyjs from 'alertifyjs';
import { saveAs } from 'file-saver';
import { CommonService } from 'src/app/services/common.service';
import { AuthService } from 'src/app/services/auth/auth.service';


@Component({
  selector: 'app-contact-financial-payments',
  templateUrl: './contact-financial-payments.component.html',
  styleUrls: ['./contact-financial-payments.component.css'],
  providers: [DatePipe]
  

})
export class ContactFinancialPaymentsComponent implements OnInit {
  submit_download_disabled: any;

  queryParams: any;
  person: any;
  section_transactions: any = [];
  isNeteable: any = [];
  payment_base: any = [];

  transaction: any = {};
  section_transaction: any;
  loading_person = false;
  loading = false;

  buro_response: any;
  buro: any;
  buro_score: any;
  activatePsico: any;
  showActivatePsico: any;

  loading_buro = false;
  psicometrico: any;
  psicometrico_response: any;
  psicometrico_array: any = [];
  status_approved: any;
   type_approved: any;
   clientQuota:any;
   invoiceCredits: any = [];
   resp_invoiceCredits: any = [];
   installments: any = [];
   installmentNexPayment :any;
view = 1;
invoiceSelected:any;
installMentSelected:any;
payment:any;
env = environment;

pagares: any = [];


  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,
    private datePipe: DatePipe,
    public socketService: SocketioService,
    private commonService: CommonService,
    public _auth : AuthService,

  ) { }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe(params => {
      this.queryParams = params;
      if (this.queryParams?.code) {

        this.getPerson();
      }

    });

  }


  getPerson = async () => {
    this.loading_person = true;
    try {

      const resp = await this._user.getPersonbyCode({ code: this.queryParams.code });
      
      const resp_invoiceCredits = await this.listClientInvoiceCredits();

      let response;
      debugger
      await Promise.all(resp_invoiceCredits.res.map(async(row: any)=> {
        response =  await this.getPagare(row._id);
        console.log(response)
        this.pagares[row._id] = response.res;
      }));
      console.log(this.pagares);

      this.person = resp.resp;
      this.resp_invoiceCredits = resp_invoiceCredits?.res;

      let invoicesPromes:any = [];
      let isNeteable:any = {};

      resp_invoiceCredits?.res?.forEach((item:any) =>{

        const query = {
          "invoice_code": item?.code
        };
        
        const promesa = this._user.getIsNeteable(query);

        invoicesPromes = [...invoicesPromes,promesa];

      });

      

       let tempIsNeteable:any = await  Promise.all(invoicesPromes);

      
    
      tempIsNeteable?.forEach((item:any) =>{

        isNeteable[item.invoice_code] =  item.is_neteable;

      });

      debugger

      this.isNeteable = isNeteable;

      
    } catch (error) {
      console.error(error);

    } 

   
 
    this.loading_person = false;


  }

  downloadFile = async (url:any, extension: string, name: string) => {
    this.submit_download_disabled = true;
    let res = await this.commonService.download(url, extension);
    if(res) {
      saveAs(res, `${name}.${extension}`);
    }
    this.submit_download_disabled = false;
  }

  getPagare = async(invoice: any) => {
    return await this._user.pagareByInvoice(invoice);
  }


  register_netable_payment = async (code:any) => {

    try {


    let action = 'continuar'


    Swal.fire({
      title: "¿Deseas saldar este crédito?",
      text: "¡No podrás revertir esto!",
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      input: "textarea",
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonText: `Si, ${action}!`,
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        try {
        
          if (login?.trim() == '') {
            return Swal.showValidationMessage(`Debe añadir una razón del  neteo`);
          }

        } catch (error) {
          Swal.showValidationMessage(`
            Request failed: ${error}
          `);
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
      if (result.isConfirmed) {
        
        const query = {
          "invoice_code":code,
          "description": result?.value
  
        };
  
          const neteado = await this._user.register_netable_payment(query);
          this.getPerson();

          alertifyjs.success(`Operción exitosa`);

        debugger
      }
    });



    
    } catch (error) {
      console.error(error);

    } 

   
 
    this.loading_person = false;


  }


  listClientInvoiceCredits = async () => {
       
    try {
         const data =  {
          code_person :this.queryParams?.code
         
         }

        return this._user.listClientInvoiceCredits(data);

      } catch (error) {
        console.error(error);
        
        
      }
  }


  getInstallments = async (invoice:any) => {
    return new Promise(async (resolve, reject) => {
      const data =  {
        code_invoice:invoice?.code 
      }
      const  installments  = await this._user.getPersonInstallmentsInvoiceCredits(data);
      resolve(installments?.res)
    });
  }


  getPersonInstallmentsInvoiceCredits = async (invoice:any) => {
    this.loading = true;

    this.invoiceSelected = invoice;
    try {
         const data =  {
          code_invoice:invoice?.code 
         
         }

       const  installments  = await this._user.getPersonInstallmentsInvoiceCredits(data);

       this.installments = installments?.res;

       this.installments.map((value:any, index:any) => {
        if(value?.installMent?.payment_status?.code != 'PaymentComplete' && !this.installmentNexPayment){

          this.installmentNexPayment = value?.installMent?.code;

        }

        value["totalpaymentReference"] = 0;

        value?.paymentReference?.forEach((value2:any) =>{
          value["totalpaymentReference"] =  value["totalpaymentReference"]  +  value2?.payment?.amount;
        });
        


        return value;
      });

      

/*
      this.installments.sort((a:any, b:any) => {
        let temp = a?.installMent?.order;
        let temp2 = b?.installMent?.order;
    
        return  temp - temp2;
      });*/
      
       
       this.view = 2;

      } catch (error) {
        console.error(error);
        
      }

      this.loading = false;

  }


  getPersonInstallmentpayment = async (installMentSelected:any) => {
       
    this.loading = true;

    this.installMentSelected = installMentSelected;
    try {
         const data =  {
          code_installMent:installMentSelected?.code 
         
         }

       const  payment  = await this._user.getPersonInstallmentpayment(data);

       this.payment_base = payment?.res;
       this.view = 3;

      } catch (error) {
        console.error(error);
        
        
      }

      this.loading = false;

  }
  

  getIdentity(item: any) {

    if (!(item?.identitys?.length > 0))
      return "";

    return `${item?.identitys[0]?.type_identity?.name} ${item?.identitys[0]?.code_identity?.name}-${item?.identitys[0]?.identity}`;

  }


  getNumberIdentity(item: any) {

    if (!(item?.identitys?.length > 0))
      return "";

    return `${item?.identitys[0]?.identity}`;

  }


  getContact(item: any) {

    if (!(item?.constacts?.length > 0))
      return "";

    return `${item?.constacts[0]?.contact}`;


  }


  sanitize(url: string) {
    //return url;
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }



  public redirect = () => {
    
    let Doc_ID;
    let Type_ID;
    let TLF;


    if (this.person?.identitys?.length > 0) {

      const data = this.person?.identitys[0];
      Type_ID = data.type_identity?.additionalType;
      Doc_ID = data.identity;

    }


    const data = {

      CED: environment.CED,
      IP: environment.IP_LOCAL,
      Type_ID,
      Doc_ID,
      PN: this.person?.person?.name,
      PA: this.person?.person?.lastName,
      FN: this.datePipe.transform(this.person?.person?.birth_date, 'yyyy-MM-dd'),
      GN: this.person?.person?.gender,
      TLF: "",
      EMAIL: ""

    }

    this.redirecFormService.postRedirect(environment.externalPsicometrico, data);

  }

  closeInstallment(){

    if(this.view == 2 ){
      this.installments = [];
      this.view = 1;
      this.invoiceSelected=null;
      this.installmentNexPayment = null;

    }else  if(this.view == 3 ){
      this.view = 2;
      this.installMentSelected = null;
      this.payment_base = null;
      this.installmentNexPayment = null;
   
    }
   
         }

restClient = async () =>  {

  if (this.person?.identitys?.length > 0) {
    this.loading = true;

    const data:any = this.person?.identitys[0];

    let params = {
    
      "client": {
        "type_identity": data.type_identity?.code,
        "code_identity": data.code_identity?.code,
        "identity": data.identity
      }
    
    };

  try {
       
    let resp = await this._user.clientQuotaReset(params);

    this.socketService.setResetstadistic(true);

    this.installments = [];
    this.resp_invoiceCredits = [];
    this.invoiceSelected=null;
    this.invoiceSelected=null;
    this.installMentSelected = null;
    this.payment_base = null;
    this.view = 1;


    this.getPerson();


   }  catch (error:any) {

        const params = error?.error?.error;
        if(params?.code){
        }

   }
   this.loading = false;

}
}

anularCompra = async (param:any) => {

  debugger
  let action = 'Anular'

  Swal.fire({
    title: `¿Deseas Anular la compra ${param?.identifier} ?`,
    text: "¡No podrás revertir esto!",
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
   /* input: "select",
    inputOptions: {
      'Referencia errada': 'Referencia errada',
      'Banco errado': 'Banco errado',
      'Soporte errado': 'Soporte errado',
      'Sin soporte': 'Sin soporte',
      'Monto errado': 'Monto errado',
      'Fecha errada': 'Fecha errada',
      'Datos de pago errados': 'Datos de pago errados',
      'No se encuentra en banco': 'No se encuentra en banco',
      'Método de pago errado': 'Método de pago errado',
      'Pago duplicado': 'Pago duplicado'
    },
    inputPlaceholder: 'Selecciona una razón',*/
    showCancelButton: true,
    confirmButtonText: `Si, ${action}!`,
    showLoaderOnConfirm: true,
    /*  preConfirm: async (reason) => {
      try {
        if (!reason) {
          return Swal.showValidationMessage(`Debe seleccionar una razón de anulación`);
        }
      } catch (error) {
        Swal.showValidationMessage(`Request failed: ${error}`);
      }
    },*/
    allowOutsideClick: () => !Swal.isLoading()
  }).then((result) => {

    if (result.isConfirmed) {

     this.anularCompraService(param?.code);


    }
  });
}



anularCompraService = async (code:any) =>  {


    let params = {
    
      "credit_code": code
    
    };

  try {
    
    debugger

    let resp = await this._user.anularCompra(params);
   this.getPerson();

   }  catch (error:any) {

        const params = error?.error?.error;
        if(params?.code){
        }

   }


}

redirectTo() {
  this.router.navigate([`/console/payments/person/${this.person?.person?.code}`], {});
}

setIdentity(){
  localStorage.setItem('identity',this.getNumberIdentity(this.person));

}

generatePagares = async (obj: any) => {
  let identity: any = this.person.identitys[0].identity
  let installments: any = await this.getInstallments(obj);
  let person: any = this.person.person;
  // console.log(obj);
  // console.log(identity);
  console.log(this.person);
  console.log(obj)
  installments = installments.filter((row: any) => {
    return row.installMent.payment_status.code === 'PaymentPending';
  });
  //{{ identity.type_identity }}: {{ identity.code_identity }}{{ identity.identity }}
  installments = installments.map((row: any) => {
    row['person'] = person;
    row['invoice'] = obj;
    row['identity'] = {
      type_identity: this.person.identitys[0].type_identity.code,
      code_identity: this.person.identitys[0].code_identity.code,
      identity
    };
    return row;
  });

  console.log(installments);
  let body = {
    template: 'template-pagare',
    config: {
      empty: '',
      pdfConfig: {
        page: {width: "87mm", height: "297mm", format: "omitir"},
        margin: { top: '0px', right: '5px', bottom: '5px', left: '5px' }
      }
    },
    obj: {
      iterators: {
        pages: installments
      },
    },
    invoice: obj._id,
  };
  try {
    let resp = await this._user.generatePagare(body);

    let response_pagare = await this.getPagare(obj._id);
    this.pagares[obj._id] = response_pagare.res;

    console.log(this.pagares);

    return resp;
  } catch (e) {
    return null;
  }
};

}
