<div *ngIf="section == 1" class="container">
    <div class=" col-12 col-md-8 offset-md-2 mt-4  p-0 ">
        <h5 class="mb-4">Consultar
        </h5>

        <div class="mt-3 my-3 p-3 bg-body rounded shadow-sm">

            <form autocomplete="nope" novalidate [formGroup]="form" (ngSubmit)="submit()" class="text-start">
                <div class="row mt-3">
                    <div class="form-group  col-12">

                        <div class="form-floating">
                            <select [ngClass]="{'error': form.controls.type_identity.invalid && form.controls.type_identity.dirty}" class="form-select" id="type_identity" formControlName="type_identity" aria-label="Floating label select example">
                            <option  value="">Selecione</option>
                            <option *ngFor="let item of type_identity"  [value]="item.code">{{item.name}}</option>
                        </select>

                            <label for="type_identity">Tipo de Documento</label>
                        </div>

                        <div class="form-control-feedback help-block" *ngIf="form.get('type_identity').errors">
                            <div *ngIf="form.get('type_identity').errors['required'] && !form.get('type_identity').pristine">
                                Campo requerido
                            </div>
                        </div>

                    </div>

                    <div class="form-group  col-4 mt-3">

                        <div class="form-floating">
                            <select [ngClass]="{'error': form.controls.code_identity.invalid && form.controls.code_identity.dirty}" class="form-select" id="code_identity" formControlName="code_identity" aria-label="Floating label select example">
                            <option  value="">Selecione</option>
                            <option *ngFor="let item of code_identity"  [value]="item.code">{{item.name}}</option>
                                     </select>



                            <label for="code_identity">Tipo</label>
                        </div>

                        <div class="form-control-feedback help-block" *ngIf="form.get('code_identity').errors">
                            <div *ngIf="form.get('code_identity').errors['required'] && !form.get('code_identity').pristine">
                                Campo requerido
                            </div>
                        </div>
                    </div>

                    <div class="form-group  col-8  mt-3">

                        <div class="form-floating ">
                            <input type="text" [ngClass]="{'error': form.controls.identity.invalid && form.controls.identity.dirty}" class="form-control text-capitalize" id="identity" formControlName="identity">
                            <label for="identity" class=" label font-weight-normal" style="word-wrap: break-word;">
                          <span >Documento </span>
                     </label>
                        </div>

                        <div class="form-control-feedback help-block" *ngIf="form.get('identity').errors">
                            <div *ngIf="form.get('identity').errors['required'] && !form.get('identity').pristine">
                                Campo requerido
                            </div>
                            <div *ngIf="form.get('identity').hasError('minlength')  && !form.get('identity').pristine">
                                Mínimo 7 caracteres
                            </div>

                            <div *ngIf="form.get('identity').hasError('maxlength')  && !form.get('identity').pristine">
                                Máximo 8 caracteres
                            </div>

                        </div>
                    </div>

                    <br>
                    <div class="form-group  col-12 mt-4 mb-2">
                        <div class=" float-end">

                            <button type="submit" [disabled]="!form.valid || submit_disabled" class="btn btn-block btn-primary ">
                                        <span >Consultar</span>
                                        <span *ngIf="submit_disabled">
                                          <i class="fa  fa-spinner fa-spin ms-2"></i>
                                        </span>
                        </button>
                        </div>

                    </div>

                </div>
            </form>

            <h5 class="text-secondary" *ngIf="person_response == 2">
                Datos no encontrados
            </h5>


            <h5 class="text-secondary" *ngIf="person_response == 3">
                Psicometrico no disponible
            </h5>

        </div>
    </div>
</div>


<!-- 
<ng-container>
    <div class="container-fluid mt-3" [style]="section == 2? 'height:'+ (screenHeight - 230)+'px;':''">

        <iframe [ngClass]="{'d-none': section != 2}" class="myIframe" name="myIframe" id="myIframe">
            <p>iframes are not supported by your browser.</p>
        </iframe>
    </div>
    <div class="text-center mt-3" *ngIf="section == 2">
        <div class="h4">Piscometrico Finalizado</div>

        <button (click)="section = 1" class="btn btn-block btn-primary ">
            <span >Continuar</span>
            
       </button>
    </div>


</ng-container> -->