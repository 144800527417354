import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/user/user.service';
import { mobiscroll, MbscCalendarOptions } from '@mobiscroll/angular';
import { DatePipe } from '@angular/common';
import * as alertifyjs from 'alertifyjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import { saveAs } from 'file-saver';

mobiscroll.settings = {
  lang: 'es',
  theme: 'ios',
  themeVariant: 'light',
};

@Component({
  selector: 'app-credit-save',
  templateUrl: './credit-save.component.html',
  styleUrls: ['./credit-save.component.css'],
})
export class CreditSaveComponent implements OnInit {
  @Output() close = new EventEmitter<any>();

  dataJson = {};

  dateSetting: MbscCalendarOptions = {
    responsive: {
      small: {
        controls: ['calendar'],
        display: 'center',
      },
      medium: {
        display: 'bubble',
      },
    },
    lang: 'es',
    theme: 'ios',
    themeVariant: 'light',
  };

  birthday: any;
  birthdayString: any = '';
  form: any;
  form2: any;
  form3: any;
  formEjecutive: any;
  rate_error = false;
  rate_load = true;

  submit_disabled: any;
  submit_download_disabled: any;
  submit_download_create: any;


  person: any;
  searchPerson: any;
  step: any = 1;
  validateNumber: any;

  type_identity: any = [];
  code_identity: any = [];
  marital_status: any;
  identityResponse: any;
  currencyResponse: any;
  result: any;
  buro: any = {};
  idInvoice: any;

  section: any = 1;
  idTransaction: any;
  errorMsg: any;
  identity: any;
  rate: any;

  idEjecutive: any = null;
  sessionUser: any;
  pagare: any = null;
  continue:any = null;

  viewForm = true;

  constructor(
    private formBuilder: FormBuilder,
    private _user: UserService,
    private router: Router,
    public _auth: AuthService,
    private sanitizer: DomSanitizer,
    public socketService: SocketioService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this._auth.auth$.subscribe((rep: any) => {
      if (rep) {
        this.sessionUser = this._auth.sessionUser;
      }
    });

    if (localStorage.getItem('idEjecutive')) {
      this.idEjecutive = localStorage.getItem('idEjecutive');
    }

    this.identity = localStorage.getItem('identity');
    localStorage.removeItem('identity');

    localStorage.removeItem('new_person');
    this.searchPerson = null;

    this.formEjecutive = this.formBuilder.group({
      type_identity: ['CI', [Validators.required]],
      code_identity: ['V', [Validators.required]],
      identity: [
        '',
        [Validators.required, Validators.minLength(6), Validators.maxLength(20)],
      ],
    });

    this.form = this.formBuilder.group({
      type_identity: ['CI', [Validators.required]],
      code_identity: ['V', [Validators.required]],
      currency: ['USD', [Validators.required]],
      identity: [
        '',
        [Validators.required, Validators.minLength(6), Validators.maxLength(20)],
      ],
      total_amount: ['', [Validators.required, Validators.min(20)]],
      plan: ['50'],
      type_invoice: ['invoice', [Validators.required]],
      invoice: [''],
      invoice_oddo: [''],
    });

    this.getLists();
    this.getRate();
  }

  getLists = async () => {
    
    const [marital_status, type_identity, code_identity] = await Promise.all([
      await this._user.getPersonMaritalStatus(),
      await this._user.getPersonTypeIdentity(),
      await this._user.getPersonCodeIdentity(),
    ]);

    this.marital_status = marital_status.resp;
    this.type_identity = type_identity.resp;
    this.code_identity = code_identity.resp;
  };

  submit = async () => {
    this.searchPerson = null;
    this.errorMsg = null;
    this.result = false;
    this.person = null;
    if (this.form.valid || true) {
      this.submit_disabled = true;
      const data = this.form.getRawValue();
      this.identityResponse = data.code_identity + data.identity;

      let params = {
        subscriber: {
          subscriber: 1,
          shop: this.sessionUser.shop,
          cash_register: 1,
          user: this.idEjecutive,
        },
        client: {
          type_identity: data.type_identity,
          code_identity: data.code_identity,
          identity: data.identity,
        },
        params: {
          plan_id: '63dc1738b5e22028eedb0521',
          currency: data.currency,
          total_amount: data.total_amount,
          chage_rate: this.rate?.amount,
        },
      };

      try {
        let resp = await this._user.clientQuota(params);

        this.currencyResponse = data.currency;

        this.searchPerson = resp?.res;
        this.section = 2;
        this.viewForm= false;
        this.submit_disabled = false;
      } catch (error: any) {
        this.viewForm = true;
        const params = error?.error?.error;
        if (params?.code) {
          this.errorMsg = params?.msg;
        }

        this.submit_disabled = false;
      }
    }
  };

  sanitize(url: string) {
    //return url;
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  creditSave = async () => {
    this.result = false;
    this.person = null;

    if (!this.submit_download_create) {
      this.submit_download_create = true;
      const data = this.form.getRawValue();
      this.identityResponse = data.code_identity + data.identity;

      let params = {
        subscriber: {
          subscriber: 1,
          shop: this.sessionUser.shop,
          cash_register: 1,
          user: this.idEjecutive,
        },
        client: {
          type_identity: data.type_identity,
          code_identity: data.code_identity,
          identity: data.identity,
        },
        params: {
          merchantNumber: data.invoice,
          merchantNumber2: data.invoice_oddo,
          plan_id: '63dc1738b5e22028eedb0521',
          referencesOrder: `${Math.floor(Math.random() * 10) + 1}`,
          currency: data.currency,
          total_amount: data.total_amount,
          chage_rate: this.rate?.amount,
          payment_params: [
            {
              amount:
                this.searchPerson?.total_currency[data.currency]
                  ?.initiala_amount,
              chage_rate: this.rate?.amount,
              currency: data.currency,
            },
          ],
        },
      };

      this.dataJson = params;

      try {
        debugger
        let resp = await this._user.creditSave(params);
        // this.socketService.setResetstadistic(true);
        // aqui
        
        this.idInvoice = resp?.res?.invoice?.identifier;

        console.log(resp);
        alertifyjs.success('Creacion exitosa');

        this.pagare = await this.generatePagares(resp?.res, params.client);

        this.submit_download_create = false;
      } catch (error: any) {
        
        this.submit_download_create = false;
      }
    }
  };

  generatePagares = async (obj: any, identity: any) => {
    let installments = obj.installMents.filter((row: any) => {
      return row.installMent.payment_status.code === 'PaymentPending';
    });
    const data = this.form.getRawValue();
    installments = installments.map((row: any) => {
      row['person'] = obj.person;
      row['invoice'] = obj.invoice;
      row['identity'] = {
        type_identity: data?.type_identity,
        code_identity: data?.code_identity,
        identity: data?.identity,
      };
      return row;
    });
    let body = {
      template: 'template-pagare',
      config: {
        empty: '',
        pdfConfig: {
          page: {width: "87mm", height: "297mm", format: "omitir"},
          margin: { top: '0px', right: '5px', bottom: '5px', left: '5px' }
        }
      },
      obj: {
        iterators: {
          pages: installments
        },
      },
      invoice: obj.invoice._id,
    };
    try {
      let resp = await this._user.generatePagare(body);
      return resp;
    } catch (e) {
      return null;
    }
  };

  downloadFile = async (url:any, extension: string, name: string) => {
    this.submit_download_disabled = true;
    let res = await this.commonService.download(url, extension);
    if(res) {
      saveAs(res, `${name}.${extension}`);
    }
    this.submit_download_disabled = false;
  }

  redirectTo() {
    this.router.navigate([`/`], {});
  }

  getRate = async (date = null, init = false, changeMount = false) => {
    try {
      this.rate_error = false;

      this.rate_load = true;

      let data: any = { subscriptor: 1 };


      if (date) {
        data = { date, subscriptor: 1 };
      }
debugger;
      try {
        const respose = await this._user.getRate(data);
        if (!respose) this.rate_error = true;

        let rate = respose?.res;

        this.rate = rate;

        this.rate_load = false;
      } catch (error) {
        console.log(error);
        this.rate_error = true;
        this.rate_load = false;
      }
    } catch (error) {
      console.log(error);
    }
  };

  setIdEjecutive() {
    const data = this.formEjecutive.getRawValue();
    let ci = data.code_identity + data.identity;

    localStorage.setItem('idEjecutive', ci);
    this.idEjecutive = ci;
  }

  moveCursorToEnd(event: any) {
    const input = event.target;
    input.selectionStart = input.selectionEnd = input.value.length;
  }

  continuar = () => {
    this.resetData();
  }
  
  resetData() {

    location.reload();
    
    /*
    this.viewForm = true;
    this.form = this.formBuilder.group({
      type_identity: ['CI', [Validators.required]],
      code_identity: ['V', [Validators.required]],
      currency: ['USD', [Validators.required]],
      identity: [
        this.identity,
        [Validators.required, Validators.minLength(6), Validators.maxLength(20)],
      ],
      total_amount: ['', [Validators.required, Validators.min(20)]],
      plan: ['50'],
      type_invoice: ['invoice', [Validators.required]],
      invoice: [''],
      invoice_oddo: [''],
    });

    this.section = 1;*/
  }

  resetSearchPerson(){

    this.form.get('invoice_oddo').setValue('');
    this.form.get('invoice').setValue('');
    this.searchPerson = null;


  }
}
