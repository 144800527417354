<div class="row">
    <div class="col-12 mt-2">
      <div class="mt-2 mb-5">
        <form
          *ngIf="!loadForm"
          autocomplete="nope"
          novalidate
          [formGroup]="form"
          class="text-start"
        >
          <div class="row">
  
            <div class="form-group  col-12 mt-3">
              <div class="form-floating">
                  <select  (change)="setMinAccount()" [ngClass]="{'error': form.controls.payment_method.invalid && form.controls.payment_method.dirty}" class="form-select" id="payment_method" formControlName="payment_method" aria-label="Floating label select example">
                      <option  value="">Selecione</option>
                      <option   value="TRANSFERENCIA">Trasferencia</option> 
                      <option   value="PAGO_MOVIL">Pago Movil</option> 
                      <option   value="CONTRACT">Contrato</option> 
                      <option   value="CONTRACT_DYNAMIC">Contrato Dinamico</option> 
                      <option   value="EFECTIVO">Efectivo Bs</option> 
                      <option   value="EFECTIVO_USD">Efectivo USD</option> 

                  </select>
  
                  <label for="payment_method">Tipo</label>
              </div>
  
              <div class="form-control-feedback help-block" *ngIf="form.get('payment_method').errors">
                  <div *ngIf="form.get('payment_method').errors['required'] && !form.get('payment_method').pristine">
                      Campo requerido
                  </div>
              </div>
          </div>



          <div class="form-group  col-4  mt-3" *ngIf="form.getRawValue()?.payment_method == 'TRANSFERENCIA' || form.getRawValue()?.payment_method == 'PAGO_MOVIL'" >
            <div class="form-floating">
                <select [ngClass]="{'error': form.controls.code_identity.invalid && form.controls.code_identity.dirty}" class="form-select" id="code_identity" formControlName="code_identity" aria-label="Floating label select example">
                    <option  value="">Selecione</option>
                    <option  value="V">V</option>
                    <option  value="J">J</option>
                    <option  value="E">E</option>
                    <option  value="P">P</option>
                    <option  value="G">G</option>

                 </select>
                <label for="code_identity">Codigo</label>
            </div>

            <div class="form-control-feedback help-block" *ngIf="form.get('code_identity').errors">
                <div *ngIf="form.get('code_identity').errors['required'] && !form.get('code_identity').pristine">
                    Campo requerido
                </div>
            </div>
        </div>

        <div class="form-group  col-8  mt-3" *ngIf="form.getRawValue()?.payment_method == 'TRANSFERENCIA' || form.getRawValue()?.payment_method == 'PAGO_MOVIL'">

            <div class="form-floating ">
                <input type="number" [ngClass]="{'error': form.controls.identity.invalid && form.controls.identity.dirty}" class="form-control text-capitalize" id="identity" formControlName="identity">
                <label for="identity" class=" label font-weight-normal" style="word-wrap: break-word;">
      <span >Documento </span>
 </label>
            </div>

        </div>

  
            <div class="form-group col-12  mt-3"  *ngIf="form.getRawValue()?.payment_method == 'TRANSFERENCIA' || form.getRawValue()?.payment_method == 'PAGO_MOVIL' ">
              <div class="form-floating">
                <select
                  (change)="setBank()"
                  [ngClass]="{
                    error: form.controls.bank.invalid && form.controls.bank.dirty
                  }"
                  class="form-select"
                  id="bank"
                  formControlName="bank"
                  aria-label="Floating label select example"
                >
                  <option value="">Selecione</option>
                  <option [value]="item.code" *ngFor="let item of banks">
                    {{ item.name }}
                  </option>
                </select>
  
                <label for="bank">Banco</label>
              </div>
  
              <div
                class="form-control-feedback help-block"
                *ngIf="form.get('bank').errors"
              >
                <div
                  *ngIf="
                    form.get('bank').errors['required'] &&
                    !form.get('bank').pristine
                  "
                >
                  Campo requerido
                </div>
              </div>
            </div>
  
            <div class="form-group col-12 mt-3 d-none" >
              <div class="form-floating">
                <select
                  [ngClass]="{
                    error:
                      form.controls.currency.invalid &&
                      form.controls.currency.dirty
                  }"
                  class="form-select"
                  id="currency"
                  formControlName="currency"
                  aria-label="Floating label select example"
                >
                  <option value="">Selecione</option>
                  <option value="63ffb65dbb023a686c40ab8c">BS</option>
                </select>
  
                <label for="currency">Moneda</label>
              </div>
  
              <div
                class="form-control-feedback help-block"
                *ngIf="form.get('currency').errors"
              >
                <div
                  *ngIf="
                    form.get('currency').errors['required'] &&
                    !form.get('currency').pristine
                  "
                >
                  Campo requerido
                </div>
              </div>
            </div>
  
            <div class="form-group col-12 mt-3">
              <div class="form-floating" *ngIf="form.getRawValue()?.payment_method == 'TRANSFERENCIA'">
                <select
                  [ngClass]="{
                    error:
                      form.controls.account_type.invalid &&
                      form.controls.account_type.dirty
                  }"
                  class="form-select"
                  id="account_type"
                  formControlName="account_type"
                  aria-label="Floating label select example"
                >
                  <option value="">Selecione</option>
                  <option value="00">Corriente</option>
                  <option value="01">Ahorro</option>
                </select>
  
                <label for="currency">Tipo de Cuenta</label>
              </div>
  
              <div
                class="form-control-feedback help-block"
                *ngIf="form.get('currency').errors"
              >
                <div
                  *ngIf="
                    form.get('currency').errors['required'] &&
                    !form.get('currency').pristine
                  "
                >
                  Campo requerido
                </div>
              </div>
            </div>
  
            <div class="mt-3" *ngIf="form.getRawValue()?.payment_method == 'TRANSFERENCIA'">
              
              <div >
                <div class="form-group">
                  <div class="form-floating">
                   
                    <input
                      (keyup)="validate_account_number()"
                      type="text"
                      [ngClass]="{
                        error:
                          form.controls.account_number.invalid &&
                          form.controls.account_number.dirty
                      }"
                      class="form-control text-capitalize"
                      id="account_number"
                      (input)="onChange($any($event))"
                      maxlength="20"
                      formControlName="account_number"
                      appNumbersOnly
                      #miInput
                    />
                    <label
                      for="account_number"
                      class="label font-weight-normal"
                      style="word-wrap: break-word"
                    >
                      <span>Número de cuenta</span>
                    </label>
                  </div>
  
                  <div
                    class="form-control-feedback help-block"
                    *ngIf="form.get('account_number').errors"
                  >
                    <div
                      *ngIf="
                        form.get('account_number').errors['required'] &&
                        !form.get('account_number').pristine
                      "
                    >
                      Campo requerido
                    </div>
                  </div>
  
                  <div
                    *ngIf="
                      !validation_account_number &&
                      !form.get('account_number').pristine
                    "
                    class="form-control-feedback help-block"
                  >
                    <span> Deben ser 20 dígitos </span>
                  </div>
                </div>
              </div>
            </div>
  
            <div class="form-group  col-4  mt-1" *ngIf="form.getRawValue()?.payment_method == 'PAGO_MOVIL'">
              <div class="form-floating">
                  <select [ngClass]="{'error': form.controls.code_phone.invalid && form.controls.code_phone.dirty}" class="form-select" id="code_phone" formControlName="code_phone" aria-label="Floating label select example">
                      <option  value="">Selecione</option>
                      <option  value="58414">0414</option>
                      <option  value="58424">0424</option>
                      <option  value="58412">0412</option>
                      <option  value="58416">0416</option>
                      <option  value="58426">0426</option>
                      
                   </select>
                  <label for="code_phone">código</label>
              </div>
  
          </div>
  
       
          <div *ngIf="form.getRawValue()?.payment_method == 'PAGO_MOVIL'" class="form-group  col-8  mt-1">
  
              <div class="form-floating ">
                  <input (keyup)="validatePhone()" type="text" [ngClass]="{'error': form.controls.phone.invalid && form.controls.phone.dirty}" class="form-control text-capitalize" id="identity" formControlName="phone" placeholder="0000000">
                  <label for="phone" class=" label font-weight-normal" style="word-wrap: break-word;">
                  <span >Número </span>
   </label>
              </div>
          </div>

        

          <div class="mt-3" *ngIf="form.getRawValue()?.payment_method == 'CONTRACT' || form.getRawValue()?.payment_method == 'CONTRACT_DYNAMIC'">
              
            <div >
              <div class="form-group">
                <div class="form-floating">
                 
                  <input
                    type="text"
                    class="form-control text-capitalize"
                    id="name_contract"
                    formControlName="name_contract"
                    appNumbersOnly
                  />
                  <label
                    for="name_contract"
                    class="label font-weight-normal"
                    style="word-wrap: break-word"
                  >
                    <span>Alias</span>
                  </label>
                </div>

                <div
                  class="form-control-feedback help-block"
                  *ngIf="form.get('name_contract').errors"
                >
                  <div
                    *ngIf="
                      form.get('name_contract').errors['required'] &&
                      !form.get('name_contract').pristine
                    "
                  >
                    Campo requerido
                  </div>
                </div>

                
              </div>
            </div>
          </div>
          <div class="mt-3" *ngIf="form.getRawValue()?.payment_method == 'CONTRACT'">
              
            <div >
              <div class="form-group">
                <div class="form-floating">
                 
                  <input
                    type="text"
                    class="form-control text-capitalize"
                    id="number_contrac"
                    formControlName="number_contrac"
                    appNumbersOnly
                  />
                  <label
                    for="number_contrac"
                    class="label font-weight-normal"
                    style="word-wrap: break-word"
                  >
                    <span>Numero de contratro</span>
                  </label>
                </div>

                <div
                  class="form-control-feedback help-block"
                  *ngIf="form.get('number_contrac').errors"
                >
                  <div
                    *ngIf="
                      form.get('number_contrac').errors['required'] &&
                      !form.get('number_contrac').pristine
                    "
                  >
                    Campo requerido
                  </div>
                </div>

                
              </div>
            </div>
          </div>
        
  
            <br />
            <div class="form-group col-12 mt-4 mb-2">
              <div class="float-end">
                <button
                  (click)="submit()"
                  [disabled]="
                    !form.valid || submit_disabled 
                  "
                  class="btn btn-block btn-primary"
                >
                  <span>Agregar</span>
                  <span *ngIf="submit_disabled">
                    <i class="fa fa-spinner fa-spin ms-2"></i>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </form>
        <ng-container *ngIf="loadForm || loadPerson_Address">
          <div class="w-100 text-center mt-2">
            <img src="./assets/public/images/loading.gif " alt=" " />
          </div>
        </ng-container>
      </div>
  
      <div class="card mt-3 mb-2" *ngFor="let item of accounts; let i = index">
        <div class="card-body">
          <div class="d-flex">
            <div>
              <div class="card-title ">
  
                <!--<pre>{{ item | json }}</pre> -->
                <div class="fw-bold">{{item?.payment_method?.name}}</div>
                <span class="">{{ item?.bank?.name }}</span>
  
                <span
                  *ngIf="item?.terms_and_condition"
                  class="text-success small fw-normal"
                  > · {{ item?.terms_and_condition ? "Domiciliada" : "" }}</span
                >
                <span
                  *ngIf="item?.terms_and_condition && item?.confirmed"
                  class="text-success small fw-normal"
                  > · {{ item?.confirmed ? "Confirmada" : "" }}</span
                >
              </div>
              <div class="mt-2" *ngIf="item?.account_type">
                {{ item?.account_type==="00"?"Corriente": "Ahorro" }} ({{ item?.currency?.name }})
              </div>

              <div *ngIf="item?.name_contract">
                {{ item?.name_contract }}
              </div>
  
              <div *ngIf="item?.identity">
                {{ item?.identity }}
              </div>

              <div *ngIf="item?.taxId">
                {{ item?.taxId }}
              </div>
  
              <div *ngIf="item?.account_number">
                {{ item?.account_number }}
              </div>
  
              <div *ngIf="item?.phone">
                {{ item?.phone }}
              </div>

              
              <div *ngIf="item?.number_contrac">
                {{ item?.number_contrac }}
              </div>

  
              <p class="card-text">
                <span class="small" *ngIf="item?.terms_and_condition && !item?.confirmed && !item?.code_issues"><i class="fas fa-exclamation-triangle text-warning"></i>  No verificada</span>
                <span class="small" *ngIf="item?.terms_and_condition && item?.code_issues"><i class="fas fa-exclamation-triangle text-danger"></i>  &nbsp;<span class="fw-bold">{{ item?.confirmation_issues }}</span> {{ item?.code_issues }}</span>
              </p>
            </div>
  
            <div *ngIf="!estatus_approve || _auth.IsPermitid(['OWNER'])"  class="ms-auto text-end mt-4">
            <!--<a
                class=" mr-4 small"
                [href]="item?.file?.url"
                target="_blank" title="Soporte"
                *ngIf="item?.file"
                ><i class="fas fa-paperclip"></i></a
              >&nbsp;
              <a
                class=" mr-4 small active-cursor"
                title="Editar"
                (click)="edit_account(item?._id)"
                *ngIf="!item?.confirmed"
                ><i class="fas fa-edit"></i></a
              >&nbsp;
   -->  
              <a
                class=" small"
                (click)="delete_account(item?._id)"
                title="Eliminar"
                *ngIf="!item?.terms_and_condition && !item?.confirmed"
                ><i class="fas fa-trash"></i></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  

