

import { CommonModule } from '@angular/common';
import {
  Component,
  forwardRef,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { UserService } from 'src/app/services/user/user.service';
import * as alertifyjs from 'alertifyjs';
import { AuthService } from 'src/app/services/auth/auth.service';


import Swal from'sweetalert2';

import {
  mobiscroll,
  MbscDatetimeOptions,
  MbscFormOptions,
} from '@mobiscroll/angular';



@Component({
  selector: 'app-payment-method-internal',
  templateUrl: './payment-method-internal.component.html',
  styleUrls: ['./payment-method-internal.component.css']
})
export class PaymentMethodInternalComponent implements OnInit {

  
  @Input() code_person: any;
  @Output() changeView = new EventEmitter<any>();
  @Output() editView = new EventEmitter<any>();
  @ViewChild('miInput') miInput: ElementRef | undefined;
  @Output() resetStatus = new EventEmitter<any>();
  @Input() estatus_approve: any;
  

  @ViewChild('miInput') miInputVariable: ElementRef | undefined;
  mobileSettingsFile: MbscDatetimeOptions = {
    display: 'bottom',
  };

  form: any;
  submit_disabled: any;
  min_account:any = 20;
  country: any;
  city: any;
  state: any;
  type: any;

  errorMsg: any;
  loadForm: any;
  loadPerson_Address: any;
  section_transactions: any = [];
  accounts: any = [];
  section_complete: any;
  validation_account_number: any;
  banks: any = [];
  bank: any;

 account_type: any = {

    "TRANSFERENCIA": "640b83e26522a425711e006f",
    "PAGO_MOVIL": "640b837204182a2477e87a2e",
    "DEPOSITO_USD": "654af6db9c43421dd65304d9",
    "EFECTIVO": "640b837204182a2477e87a2c",
    "EFECTIVO_USD": "66bba5ba6302d560618de326",

    "INITIAL": "63dc5345f038ca66d68a3bfa",
    "DEPOSITO_BS": "654af6c79c43421dd65304d7",
    "DOMICILIATION": "65a58080e0da26a180fd3e98",
    "CONTRACT":"66bb63436302d560618de315",
    "CONTRACT_DYNAMIC":"66df7baef2ad56cade16fae3"

  };

  formDataUpload: any = null;
  fileName = '';
  value = '';
  last_value = this.value;

  sessionUser: any;
  validationPhone: any;


 

  constructor(
    private _common: CommonService,
    private formBuilder: FormBuilder,
    private _user: UserService,
    public _auth: AuthService
  ) {}

  ngOnInit(): void {
    this.sessionUser = this._auth.sessionUser;
    this.form = this.formBuilder.group({
      
      bank: [''],
      currency: ['63ffb65dbb023a686c40ab8c', [Validators.required]],
      payment_method: ['', [Validators.required]],
      account_type: [''],
      account_number: [''],
      phone: [''],
      code_phone: ['0414'],
      code_identity: [''],
      identity: [''],
      number_contrac: [''],
      name_contract: [''],


    });

    this.getAccounts();
    this.getBanks();
  }

  ngAfterViewInit() {
    if(this.miInput) {
      this.miInput.nativeElement.onpaste = function(e: any) {
        e.preventDefault();
      };
      this.miInput.nativeElement.oncopy = function(e: any) {
        e.preventDefault();
      };
    }
  }

  onChange = ($event: any) => {
    let target = $event.target as HTMLInputElement;
    let value = target.value;

    if (value) {
      if (value && value.length >= 4) {
        this.value = value;
        this.last_value = this.value;
      } else {
        this.value = this.last_value;
      }
    } else {
      this.value = this.last_value;
    }

    this.form.patchValue({
      account_number: this.value,
    });
  };

  reset() {
    this.formDataUpload = null;
    this.fileName = '';
  }

  onFileSelected = async (event: any) => {
    const file: File = event.target.files[0];
    if (file) {
      this.fileName = file.name;
      this.formDataUpload = file;
    }
  };

  setBank = async () => {
    try {
      const data = this.form.getRawValue();

      let banks_resp = this.banks?.filter((item: any) => {
        return item.code == data.bank;
      });

      this.bank = banks_resp[0];
      this.value = this.bank.bankCode;
      this.last_value = this.value;
      this.form.patchValue({
        account_number: this.bank.bankCode,
      });
    } catch (error) {
      console.error(error);
    }
  };

  getBanks = async () => {
    try {
      const resp = await this.getBanksServer();
      this.banks = resp.res.filter((item: any) => {
        console.log(item.showClient)
        return item.bankCode ? item.showClient=== true ? true : false : false;
      });
      this.setBank();
    } catch (error) {
      console.error(error);
    }
  };

  getBanksServer = async () => {
    try {
      return this._user.getBanks({});
    } catch (error) {
      console.error(error);
    }
  };

  getLists = async () => {
    try {
      this.loadForm = true;
      const [country, city, state, type] = await Promise.all([
        await this._common.getCountry({}),
        await this._common.getCity({}),
        await this._common.getState({}),
        await this._common.getTypeAddress({}),
      ]);

      this.country = country.resp;
      this.city = city.resp;
      this.state = state.resp;
      this.type = type.resp;
      this.loadForm = false;
    } catch (error) {
      console.log(error);
    }
  };

  getAccounts = async () => {
    try {
      this.loadPerson_Address = true;
      let resp = await this._common.getPayment_method({
        code_person: this.code_person,
      });

      this.accounts = resp?.resp;
      this.loadPerson_Address = false;
    } catch (error) {
      console.log(error);
      this.loadPerson_Address = false;
    }
  };

  edit_account = async (payment_method_customer: any) => {
    this.editView.emit({
      view: 6,
      id: payment_method_customer,
    });
  };

  delete_account = async (payment_method_customer: any) => {




    Swal.fire({
      title: 'Alerta',
      text: '¿Estas seguro que deseas eliminar esta cuenta?',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showCancelButton: true,
      confirmButtonText: `Si!`,
      cancelButtonText: `Cancelar!`,
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(async (result) => {
      if (result.isConfirmed) {
  
  
        let params = {
          payment_method_customer: payment_method_customer,
        };
    
        try {

          debugger
          let resp = await this._common.delete_payment_method_internal(params);
    
          this.getAccounts();
    
          this.submit_disabled = false;
        } catch (error: any) {
          const params = error?.error?.error;
          if (params?.code) {
            this.errorMsg = params?.msg;
          }
    
          this.submit_disabled = false;
        }
    
    
  
      }
    });
  



    
  };

  submit = async () => {

    const data = this.form.getRawValue();
    debugger
     if (this.form.valid) {
      if( ( this.form.getRawValue()?.payment_method == 'TRANSFERENCIA' || this.form.getRawValue()?.payment_method == 'PAGO_MOVIL') &&  data.account_number?.toString()?.substring(0,  4) == this.bank.bankCode){
        
        /*this.form.patchValue({
          account_number: this.bank.bankCode,
        });
        this.submit_disabled = false;
        alertifyjs.error('Los primeros 4 dígitos del número de cuenta no son válidos para una cuenta del banco seleccionado.');
        return*/
      }     
        this.submit_disabled = true;
        

        let params = {
          code_person: this.code_person,
          code_bank: data.bank,
          currency: this.form.getRawValue()?.payment_method == 'EFECTIVO_USD'?'63ffb65dbb023a686c40ab8a' : data.currency,
          payment_method: this.account_type[data.payment_method],
          account_type: data.account_type,
          account_number: this.form.getRawValue()?.payment_method == 'TRANSFERENCIA'?data.account_number:null,
          user: this.sessionUser.uid,
          payment_active:true,
          phone:this.form.getRawValue()?.payment_method == 'PAGO_MOVIL'?`${data?.code_phone}${data?.phone}`:null,
          identity:`${data?.code_identity}${data?.identity}`,

          number_contrac:  data.number_contrac,
          name_contract:  data.name_contract,

        };

        try {
          let resp = await this._common.setPayment_method_internal(params);
          // let resp = await this._common.setPayment_method(params);
          alertifyjs.success('Registro exitoso');
          

          this.form = this.formBuilder.group({
   
            bank: [''],
            currency: ['63ffb65dbb023a686c40ab8c', [Validators.required]],
            payment_method: ['', [Validators.required]],
            account_type: [''],
            account_number: [''],
            phone: [''],
            code_phone: [''],
            code_identity: [''],
            identity: [''],
            number_contrac: [''],
            name_contract: [''],

          });

          this.reset();

          this.getAccounts();

          this.setBank();

          this.resetStatus.emit(true);

          this.submit_disabled = false;
        } catch (error: any) {
          const params = error?.error;
          if (params) {
            this.errorMsg = params?.msg;
          }
          alertifyjs.error(this.errorMsg);
          this.submit_disabled = false;
        }

    }
  };

  setChange(View: any) {
    this.changeView.emit(View);
  }

  validate_account_number = () => {
    let campo = this.form.controls.account_number.value;

    if (campo.length == this.min_account) {
      this.validation_account_number = true;
    } else {
      this.validation_account_number = false;
    }
  };


validatePhone = () =>{


  let campo = this.form.controls.phone.value;
   

     if(campo &&  /^\d+$/.test(campo) && campo.length > 6){


       let valueCampo = campo.substr(0, 7);

       this.form.get('phone').setValue(valueCampo);


     this.validationPhone = true;

    }else{
      
      this.validationPhone = false;

    }

}

setMinAccount(){

  if (this.form.getRawValue()?.payment_method == 'TRANSFERENCIA') {
    
    this.min_account= 20;
  }else{
    this.min_account= 0;
  }


}

}
