import { Component, OnInit,EventEmitter, Output, Input } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-data-profile',
  templateUrl: './data-profile.component.html',
  styleUrls: ['./data-profile.component.css']
})
export class DataProfileComponent implements OnInit {
  section_transactions: any = [];
  @Input() code_person: any;
  @Input() person: any;
  @Input() estatus_approve: any;


  @Output() changeView = new EventEmitter<any>();
  @Output() resetStatus = new EventEmitter<any>();

  sections_status:any;
  loadPerson_Address:any;
  personAddress:any;
  customer:any;
  accounts:any;

  constructor(   
     private _user: UserService,
     private _common: CommonService

    ) { }

  ngOnInit(): void {
    

    this.getSection_transaction_by_person();
    this.getPerson_Address();
    this.getCustomer();
    this.getAccounts();

  }



getCustomer = async () =>  {

  try {
   // this.loadForm = true;

     let resp =  await this._common.getcustomer({ code_person:this.code_person});

     this.customer = resp.resp;
  } catch (error) {
    console.log(error);
    
  }

}

  getIdentity(item: any) {

    if (!(item?.identitys?.length > 0))
      return "";

    return `${item?.identitys[0]?.type_identity?.name} ${item?.identitys[0]?.code_identity?.name}-${item?.identitys[0]?.identity}`;


  }


  getContact(item: any) {
    if (!(item?.constacts?.length > 0))
      return "";

    return `${item?.constacts[0]?.contact}`;


  }


  getEmailContact(item: any) {
        if (!(item?.constacts?.length > 0) || !(item?.constacts[1]))
          return "";
    
        return `${item?.constacts[1]?.contact}`;
    
    
      }
    

getPerson_Address = async () =>  {

  
  try{
    this.loadPerson_Address= true;
  let resp =  await this._common.getPerson_Address({code_person:this.code_person})

  this.personAddress  = resp?.resp;
  this.loadPerson_Address= false;

  } catch (error) {
    console.log(error);
    this.loadPerson_Address= false;

  }

}



getSection_transaction_by_person = async (buro:any = true) => {

  this.sections_status = {};
 
   try {
 
   const resp = await this._user.getSection_transaction_by_person({ code: this.code_person,type:'DATA_CLIENT' });
 
   const result = resp.resp;
   let temp: any = {};
 
   if (result?.section_transactions?.length > 0) {
 
        result?.section_transactions.map((obj: any) => {
 
       temp[obj?.section_transaction?.code] = obj;
 
      this.sections_status[obj?.section_transaction?.code] = obj?.condition;

       return obj;

     });
 
   }
  
 } catch (error) {
 
   console.log(error);
     
 }
 }

  getSectionTransactions = async () => {

    let filter ={type:'DATA_CLIENT'}
    const resp = await this._user.getSectionTransactions(filter);
    this.section_transactions = resp.resp;

  }

  setChange(View:any){
    this.changeView.emit(View);

   }

   


getAccounts = async () =>  {

  
  try{
    this.loadPerson_Address= true;
    let resp =  await this._common.getPayment_method({code_person:this.code_person})

  this.accounts  = resp?.resp;
  this.loadPerson_Address= false;

  } catch (error) {
    console.log(error);
    this.loadPerson_Address= false;

  }

}



}
