import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MbscDatetimeOptions } from '@mobiscroll/angular';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import { UserService } from 'src/app/services/user/user.service';
import * as alertifyjs from 'alertifyjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-carga-masiva-client-proveedores',
  templateUrl: './carga-masiva-client-proveedores.component.html',
  styleUrls: ['./carga-masiva-client-proveedores.component.css'],
})
export class CargaMasivaClientProveedoresComponent implements OnInit {
  loadingXls: any;
  type: any = '';
  allie: any = '';
  fileName = '';
  formDataUpload: any = null;
  trace_documets: any = [];
  loadingXls2: any;

  mobileSettingsFile: MbscDatetimeOptions = {
    display: 'bottom',
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,
    public socketService: SocketioService
  ) {}

  ngOnInit(): void {
    //this.getTrace_documets('MASIVA');
  }

  reload_noti() {
    if(this.type==="C") {
      this.getTrace_documets('MASIVA');

    } else {
      this.getTrace_documets('MASIVA_' + this.type);

    }
  }

  onFileSelected = async (event: any) => {
    const file: File = event.target.files[0];
    /*
    const fileSizeInBytes = file.size;
    const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
    
    console.log(`El tamaño del archivo es de ${fileSizeInMB.toFixed(2)} MB`);
  */

    if (file) {
      this.fileName = file.name;
      this.formDataUpload = file;

      /* const formData = new FormData();
  
        formData.append("archivo", file);
        
        formData.append("params", JSON.stringify({
           nombre:"juan soto"
        })
  
        
      );*/
      // const resp = await this._LaService.setUpload(formData);
    }
  };

  submit = async () => {
    this.loadingXls = true;
    let error = false;
    try {
      const formData = new FormData();

      let resp;
      if (this.formDataUpload && this.type == 'C') {
        formData.append('file', this.formDataUpload);
        formData.append('type', this.allie);

        if (this.allie !== '') {
          resp = await this._user.bulk_client_upload(formData);
        } else {
          alertifyjs.error(`Seleccione el aliado`);

          error = true;
        }
      } else {
        formData.append('file', this.formDataUpload);
        formData.append('s_p', this.type);

        resp = await this._user.bulk_provider_upload(formData);
      }

      if (!error) {
        this.reload_noti();

        alertifyjs.success(`Solicitud de carga masiva exitosa`);
      }

      this.loadingXls = false;
    } catch (error: any) {
      if (error.status == 400) {
        alertifyjs.error(error.error.msg);
      } else {
        alertifyjs.error(`Error al enviar  carga masiva de clientes`);
      }
    }

    this.loadingXls = false;
  };

  public getTrace_documets = async (type: any) => {
    try {
      try {
        let result = await this._user.traceDocumentsGet({ type });

        this.trace_documets = result.resp;
        debugger;
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      this.loadingXls2 = false;
    }
  };

  dowloader_document(url: any) {
    let url_f = `${environment.BASE_LA}/${url}`;
    window.open(`${url_f}`);
  }
}
