import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-consulta-psicometrico',
  templateUrl: './consulta-psicometrico.component.html',
  styleUrls: ['./consulta-psicometrico.component.css'],
  providers: [DatePipe]

})
export class ConsultaPsicometricoComponent implements OnInit {
  form:any;
  submit_disabled:any;
  result:any;
  searchPerson:any;
  person:any;
  section:any= 1;
  screenHeight =screen.height;
  type_identity:any = [];
  code_identity:any = [];
  person_response:any ;

  constructor(
    private formBuilder: FormBuilder,
    private _user:UserService,
    private datePipe: DatePipe,
    private router: Router,
    public _auth: AuthService,
    private redirecFormService:RedirecFormService

  ) { }

  ngOnInit(): void {

    this.form = this.formBuilder.group({

      type_identity: ['CI',[Validators.required]],
      code_identity: ['V',[Validators.required]],
      identity: ['',[Validators.required,Validators.minLength(7),Validators.maxLength(8)],]
      
  });

  this.getLists();

  }

  getLists = async () =>  {
    const [ type_identity,code_identity] = await Promise.all([
      await this._user.getPersonTypeIdentity(),
      await this._user.getPersonCodeIdentity()
  ]);

    this.type_identity = type_identity.resp;
    this.code_identity = code_identity.resp;
  }


    submit = async () =>  {
      this.person_response = null;
      this.result=false;
      this.person = null;
      if ( this.form.valid) {
          this.submit_disabled = true;
          const data = this.form.getRawValue();

      try {
          
        let resp = await this._user.getPersonByIdentifier(data);
        this.searchPerson =data;
        this.result=true;
        if(resp?.resp){
          this.person = resp.resp;

          if( this.person?.person?.active_psicometrico){

            this.setStatus_psicometrico(false);
            this.setStart_psicometrico();

            
            this.redirectPsicometrico();

          }else{

            this.person_response= 3;

          }
          
        }else{
          this.person_response= 2;
          this.person = null
        }
        this.submit_disabled = false;

      } catch (error:any) {

          this.submit_disabled = false;
      }
    }
  }



public redirectPsicometrico = () =>{

  const form = this.form.getRawValue();

    const data = {
      CED: environment.CED,
      IP: environment.IP_LOCAL,
      Type_ID:form.code_identity,
      Doc_ID:form.identity,
      PN: this.person?.person?.name,
      PA: this.person?.person?.lastName,
      FN:  this.datePipe.transform(this.person?.person?.birth_date, 'yyyy-MM-dd'),
      GN: this.person?.person?.gender,

    }
    debugger

    this.redirecFormService.postRedirect(environment.externalPsicometrico,data);
    
   //this.section = 2;

  }

  
  
  setStatus_psicometrico = async (active_psicometrico:boolean) => {
    const resp = await this._user.setStatus_psicometrico({ code: this.person?.person?.code,active_psicometrico });
    this.person.person = resp.resp;
  }

  setStart_psicometrico = async () => {
    const form = this.form.getRawValue();


    const params = {
      "DocID": form.identity,
      "TypeID": form.code_identity,
      "ClieID": environment.ClieID,
      "IP": environment.IP_LOCAL,
      "code_person":this.person?.person?.code
    };

    const resp = await this._user.setStart_psicometrico(params);
    this.person.person = resp.resp;
  }
  
  
  
}
