import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import { UserService } from 'src/app/services/user/user.service';
import { mobiscroll, MbscDatetimeOptions, MbscFormOptions } from '@mobiscroll/angular';

mobiscroll.settings = {
  lang: 'es',
  theme: 'ios',
  themeVariant: 'light',
};
const now = new Date().setHours(0,0,0,0);
let week = [ new Date().setHours(0,0,0,0) ,new Date().setHours(23,59,59,999)];

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css'],
  providers: [DatePipe]

})
export class InvoicesComponent implements OnInit {
  loading:any;
  invoices:any = [];

  params_invoice:any;


  orders:any = [];
  columns:any = [];
  rows:any = [];

  rows_base:any = [];

  steps: any =[];
  stepsObj: any ={};

  // filtros
  selectedResponsable: string = ''; 
  selectedStatus: string = '';
  //selectedRange: Array < Date > = week;
  selectedRange: any;

  dateSetting: MbscDatetimeOptions = {
    lang: 'es',
    theme: 'ios',
    themeVariant: 'light',
    max:now,
    responsive: {
      small: {
          controls: ['calendar'],
          display: 'center'
      },
      medium: {
          display: 'bubble',
      }
  }
  
  };


  constructor( 
       private activatedRoute: ActivatedRoute,
    private router: Router,
    public _user: UserService,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,
    private datePipe: DatePipe,
    public socketService: SocketioService,
    private commonService: CommonService,
    private offcanvasService: NgbOffcanvas,

    public _auth : AuthService) { }

  ngOnInit(): void {



  
  this.rows = [ ];
  
  // Definición de columnas
this.columns = [

    { prop: '#', name: '#', minWidth: 20, width: 20 },
    { prop: 'orden', name: 'Orden', width: 200 },
    { prop: 'revision', name: 'Revision', width: 200 },
    { prop: 'archivos', name: 'Archivos',width: 50 },
    { prop: 'note', name: 'Notas', minWidth: 30, width: 30 },
    { prop: 'organizacion', name: 'Organizacion', minWidth: 200},
    { prop: 'proveedor', name: 'Proveedor', minWidth: 200},

    { prop: 'fecha', name: 'Fecha', width: 100},
    { prop: 'hora', name: 'Hora', width: 120 },
    { prop: 'moneda', name: 'Moneda',width: 50 },
    { prop: 'monto', name: 'Monto', minWidth: 60 }, // Ajusta el ancho aquí
    { prop: 'estatus', name: 'Estatus', minWidth: 150 },
    { prop: 'responsable', name: 'Responsable', minWidth: 200 }
    
];

  
this.socketService.reload_order.subscribe((resp: any) => {

    
    this.listClientInvoiceCredits();


});



  this.getSteps();

  }
  


  listClientInvoiceCredits = async () => {
       
    try {
         const data =  {}

        const invoice_resp =  await  this._user.getInvoiceCreditsDashboardPost(data);
 this.invoices = invoice_resp?.res;
 
        let i = 1;
    let rows:any = [];


    invoice_resp?.res?.forEach((obj: any) => {
      const fechaHoraLocal = new Date(obj?.date);
      // Restar 4 horas para ajustar a Venezuela
        //fechaHoraLocal.setHours(fechaHoraLocal.getHours() - 4);
        
        // Formatear la nueva fecha y hora en formato legible
        const fechaCompleta = fechaHoraLocal.toLocaleString('es-VE', {timeZone: 'America/Caracas'});
        
        const horaCompleta = fechaCompleta.split(" ")[1].split(":").slice(0, 2).join(":");
        const hora = horaCompleta.length === 5 ? horaCompleta + " AM" : horaCompleta.slice(0, 5) + " PM";

        const fechaArr = fechaCompleta.split(",")[0].split("/");

        const dia = fechaArr[0].padStart(2, '0'); // Agrega ceros a la izquierda si es necesario
        const mes = fechaArr[1].padStart(2, '0'); // Agrega ceros a la izquierda si es necesario
        const anio = fechaArr[2];
        const fechaFormatoDMY = dia + "-" + mes + "-" + anio;


        obj?.installments?.forEach((obj2: any) => {
          if (obj2?.order > 0) {


            let organ = '';
            if( obj.customer_suscriptor){
              organ = obj?.customer_suscriptor?.audience == 'LEGAL' ? obj.customer_suscriptor?.name : `${obj?.customer_suscriptor?.name} ${obj?.customer_suscriptor?.lastName};`;


            }

            let povid = obj?.customer?.audience == 'LEGAL' ? obj.customer?.name : `${obj?.customer?.name} ${obj?.customer?.lastName};`;

            let temp =   
            { 
              '#': i,
              orden:  {order:`${obj?.identifier}-${obj2?.order}`, code_invoice:obj?.code,code_installment:obj2?.code,code_person:obj?.customer?.code} ,
              archivos: obj?.invoice_file ? `${obj?.invoice_file?.length}` : '',
              revision: obj2?.workflow?.current?.user_fullname,
              note: '',
              hora: hora,
              fecha: fechaFormatoDMY,
            
              proveedor: {name:povid,code:obj.customer?.code} ,

              organizacion: {name:organ,code:obj.customer_suscriptor?.code} ,
             
              moneda: 'USD',
              responsable:  {code: this.stepsObj[obj2?.workflow?.current?.step]?.code?this.stepsObj[obj2?.workflow?.current?.step]?.code: "Finalizado", name: this.stepsObj[obj2?.workflow?.current?.step]?.name},

              // responsable:  this.stepsObj[obj2?.workflow?.current?.step]?.name,
              monto: obj2?.amount,
              estatus:obj?.payment_status,
              audience:obj

              };
 
              
              rows.push(temp);
              i++;
            
          }


            }); 
      });                                                                                                                                                                                                                                                           


      this.rows = this.rows_base = rows;
      } catch (error) {
        console.error(error);
        
        
      }
  }
  responder = async (code:any) => {

  
}


getSteps = async () => {
 

  try {
    
     const  steps  = await this._user.getSteps({});

     this.steps = steps?.resp;

     for (const item of this.steps) {

      this.stepsObj[item.code] = item;
      console.log(this.stepsObj);
      
     }


    } catch (error) {
      console.error(error);
      
      
    }

}


setResetStatus(data:any){

  this.listClientInvoiceCredits();
  
}


pocisionBottom3= async (content: TemplateRef<any>,params_invoice:any) => {
  try {
    

    this.params_invoice = params_invoice;

    this.offcanvasService.open(content, {
      position: 'bottom',
      keyboard: false,

    });
    

  } catch (error) {
    console.log(error);
  }
};

  filter = () => {
    let current_rows_base = this.rows_base;
    if(this.selectedResponsable!=='') {
      current_rows_base = current_rows_base.filter((row: any) => row.responsable.code === this.selectedResponsable);
    } 
    if(this.selectedStatus!=='') {
      current_rows_base = current_rows_base.filter((row: any) => row.estatus.name === this.selectedStatus);
    } 

    if(this.selectedRange!==null && this.selectedRange!==undefined) {
      let from = new Date(this.selectedRange[0]);
      from.setHours(0, 0, 0, 0);

      let to = new Date(this.selectedRange[1]);
      to.setHours(23, 59, 59, 999);
      current_rows_base = current_rows_base.filter((row: any) => {
        let rowDate = this.convertDateFormat(row.fecha);
        return rowDate.getTime() >= from.getTime() && rowDate.getTime() <= to.getTime(); 
      });
    }

    this.rows = current_rows_base;
  }

  convertDateFormat(input: string): any {
    const parts = input.split('-');
    return new Date(parseInt(parts[2].toString()), parseInt(parts[1])-1, parseInt(parts[0])); 
  }

  clearRange() {
    this.selectedRange = null;
    this.filter();
  }

}
