import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

import { mobiscroll, MbscDatetimeOptions, MbscFormOptions } from '@mobiscroll/angular';
import { CurrencyPipe, DatePipe, DecimalPipe, formatCurrency, getCurrencySymbol } from '@angular/common';
import * as alertifyjs from 'alertifyjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { async } from '@angular/core/testing';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import { Location } from '@angular/common';
import { LocationServiceService } from '../../services/location-service.service';

mobiscroll.settings = {
  lang: 'es',
  theme: 'ios',
  themeVariant: 'light'
};


@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.css'],
  providers: [DecimalPipe,CurrencyPipe,DatePipe],

})


export class PostComponent implements OnInit {

  remanente:any;
  reference_length = 8;
  reference_length_max = 8;
  accounts:any;
  dueBalance:any = 0;
  dueBalanceCurrecy:any = 0;

  responseTransaction:any = {};
  formData: any = {
    formaPago: "",
    cuenta: "",
    pais: "",
    monto: this.updateValueCurrency('0'),
    descripcion: "",
    upload:null,

  };

  errorMessage = 'Error message!;'
  validationReference:any = false;
  validationAccountNumber:any = false;

  validationPhone:any = false;
  type: any;

  confirm = false;
  confirmLoading = false;
  ordernumber:any;
  selectView: any = 1;
  selectMaxView: any = 2;
  banks:any = [];
  formDataUpload:any = null;
  fileName = '';
  @Input() code_person: any;

  queryParams: any;
  loading: any;
  person: any;

  form:any;
  installment:any;


  amountF:any = 0;
  amountFB:any = 0;
  amountFB_bs:any = 0;

  totalpaymentReference = 0;

  payments = [];
  paymentsReference = [];
  accountPayment:any;
  accountPaymentOrigin:any =[];

  accountPayment_load:any;
  account:any;
  account_payment:any;
  rate :any;
  regx_total_amount = "^(([0-9]*)|(([0-9]*)\.([0-9]*)))$"

  date_base: any = new Date;
  date_base_string :any;
  deductions_payment : any = {};

  

  mobileSettingsFile: MbscDatetimeOptions = {
    display: 'bottom'
};

  mobileSettings: MbscDatetimeOptions = {
      display: 'bottom',
      themeVariant: 'dark',
  };
  

  desktopSettings: MbscDatetimeOptions = {
      display: 'bubble',
      touchUi: false
  };

  formSettings: MbscFormOptions = {
      inputStyle: 'box'
  };

  rate_load:any;
  rate_error:any;
  deduction_payment:any;
  paymentEdit:any;
  invoiceParams:any;
  installmentParams:any;
  latitude = 0;
  longitude = 0;


  multipago:any = false;
  code_person_url:any;

  constructor(
    private offcanvasService: NgbOffcanvas,
    private decimalPipe: DecimalPipe,
    private currencyPipe: CurrencyPipe,
    private router: Router,
    private route: ActivatedRoute,
    public _auth : AuthService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,
    public socketService: SocketioService,
    private datePipe: DatePipe,
    private location: Location,
    private locationService: LocationServiceService

  ) { 

  }

  ngOnInit(): void {
    this.getLocation();

  
    this.date_base_string = this.datePipe.transform(this.date_base, 'MM/dd/yyyy');

    this.getAccountPayment();
    this.getDeduction_payment();
    this.getBanks();

    this.form = this.formBuilder.group({
      description: [''],
      reference: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      code_identity: ['V'],
      identity: [''],
      total_amount: ['',[Validators.required,Validators.pattern(this.regx_total_amount)]],
      code_phone: ['0414'],
      phone: [''],
      origin_account: [''],
      accountNumber:['']
  });

  this.validateReferenceLength();



    this.activatedRoute.params.subscribe(params => {

      this.queryParams = params;
     if(localStorage.getItem('pe')){


      let paymentEdit:any = localStorage.getItem('pe');
      this.paymentEdit =  JSON.parse(paymentEdit);
      
      localStorage.removeItem('pe');

     this.selectAccount2(this.paymentEdit.payment?.account_payment);

     this.date_base = new Date(this.paymentEdit?.payment?.register_payment_date);

     this.date_base_string = this.datePipe.transform( this.date_base, 'MM/dd/yyyy');

      this.getPerson(this.paymentEdit?.payment?.owner?.code);


  
      this.getRate(this.date_base_string,true);


      let temp = {
        total_amount :  this.paymentEdit?.payment?.counterValue || '',
        reference: this.paymentEdit?.payment?.paymentMethodId || '',
        origin_account:  this.paymentEdit?.payment?.bankCode || '',
        account:  this.paymentEdit?.payment?.account || '',
        code_identity : this.paymentEdit?.payment?.cid ? this.paymentEdit?.payment?.cid?.charAt(0) : 'V',
        identity : this.paymentEdit?.payment?.cid ? this.paymentEdit?.payment?.cid?.slice(1) : '',
        code_phone : this.paymentEdit?.payment?.phone ? this.paymentEdit?.payment?.phone?.slice(0, 4) : '',
        phone : this.paymentEdit?.payment?.phone ? this.paymentEdit?.payment?.phone?.slice(4) : ''

      }

      this.form = this.formBuilder.group({
        description: [''],
        reference: [temp.reference],
        code_identity: [temp.code_identity],
        identity: [temp.identity],
        total_amount: [temp.total_amount,[Validators.required,Validators.pattern(this.regx_total_amount)]],
        code_phone: [temp.code_phone],
        phone: [temp.phone],
        origin_account: [temp.origin_account],
        accountNumber:[temp.account]

     });

     this.validatePhone();
     this.validateNumberReference(this.reference_length, this.reference_length_max);



     }else{

      if(this.queryParams?.code_person){

        this.code_person_url = this.queryParams?.code_person;
        this.getPerson(this.code_person_url);
        this.multipago = true;
        this.getRate(null,true);

        
      }else  if (this.queryParams?.invoice && this.queryParams?.installment) {

        this.invoiceParams = this.queryParams?.invoice;
        this.installmentParams = this.queryParams?.installment;

        this.getRate(null,true);


      }else {

         this.router.navigate(['/'], {});

      }

     } ;


      this.queryParams = params;
     


    });


    const type = this.route.snapshot.paramMap.get('type') || "deposit" ;
    switch (type) {

      case 'deposit':
        this.type = 'depósito';
        break;

      case 'withdrawal':
        this.type = 'retiro';
        break;

    }

    /*if (this.type !== 'depósito' && this.type !== 'retiro') {
      this.router.navigate(['/console/order/dashboard'], {});
    }*/

  }

  validateNumericInput(event: KeyboardEvent): void {
    const keyCode = event.keyCode;
    if ((keyCode < 48 || keyCode > 57) && keyCode!= 46) {
      event.preventDefault();
    }
  }

  validateReferenceLength(): void {
  
    const referenceControl = this.form.get('reference');
      referenceControl.clearValidators();
  
    referenceControl.setValidators([
      Validators.minLength(this.reference_length),
      Validators.maxLength(this.reference_length_max)
    ]);
  
    referenceControl.updateValueAndValidity();
  }


  getLocation() {
    this.locationService.getPosition().then((pos:any) => {
      this.latitude = pos.lat;
      this.longitude = pos.lng;
      console.log(`Latitud: ${this.latitude}, Longitud: ${this.longitude}`);
    }).catch((err:any) => {
      console.error('Error obteniendo la ubicación:', err);
    });
  }


  



  getDeduction_payment = async () => {

try {
  const resp = await this._user.getDeduction_payment({});
  this.deduction_payment = resp.res;
} catch (error) {
  console.log(error);
  
}
   

  }


  getPerson = async (code:any) => {
    this.loading = true;
    const resp = await this._user.getPersonbyCode({ code });
    this.person = resp.resp;
    this.loading = false;
  }


  birthdaySet(e: any) {

    if (e) {
      e.blur();
    }
  }

  selectInst(opt: any) {
    this.formData.instrumento = opt;
    this.offcanvasService.dismiss('Cross click');
    this.formData.monto = this.formData.instrumento.monto || this.updateValueCurrency('0');

  }

  onSet() {

    let s2 = this.formData?.instrumento?.monto;
    let s3 = s2.replace(",", "");

    const x = (this.formData.cantidad * parseFloat(s3)).toFixed(2);

    this.formData.monto = this.decimalPipe.transform(x, '1.2-2');
  }


  

  nextStep() {

    if (this.selectView < this.selectMaxView) {

      
      if ( this.form.valid && this.account ) {
        this.selectView = this.selectView + 1;
             
      }else{
        this.form.pristine = false;
      }

    } else if (this.confirm) {

      this.redirectTo();

     // this.form.submit();

    } else {

      if ( this.form.valid && this.account ) {
        
          if(this.paymentEdit){

            this.setUpdateOrder();

          }else{

            this.setNewOrder();

          }


             
      }

    }


  }


  redirectTo() {
    this.router.navigate([`/console/payments/person/${this.person?.person?.code}`], {});
  }

  backStep() {

    if (this.selectView > 1) {

      this.selectView = this.selectView - 1;

    }
  }

  
  equal(a: number, b: number) {
    const m = a == this.selectMaxView;
    return m;
  }

  detectBrowser() {
    var userAgent = navigator.userAgent;
    if (userAgent.indexOf("Chrome") > -1) {
        return "Chrome";
    } else if (userAgent.indexOf("Safari") > -1) {
        return "Safari";
    } else if (userAgent.indexOf("Firefox") > -1) {
        return "Firefox";
    } else if (userAgent.indexOf("MSIE") > -1 || userAgent.indexOf("Trident") > -1) {
        return "Internet Explorer";
    } else if (userAgent.indexOf("Edge") > -1) {
        return "Edge";
    } else {
        return "Unknown";
    }
}


  getDeviceInfo() {
    const userAgent = this.detectBrowser();
    const platform = navigator.platform;
    const version = navigator.appVersion;

    let platform_text = platform.split(" ");
  
    return {
      userAgent,
      platform: platform_text[0],
      version
    };
  }


  setNewOrder = async () => {
    let info = this.getDeviceInfo();
    let client_identify: any = {
      ipaddress: "",
      browser_agent: info?.userAgent ?? "",
      mobile: {
        manufacturer: "",
        model: "",
        os_version: info?.platform ?? "",
        location: {
          lat: this.latitude,
          lng: this.longitude,
        },
      },
    };

    console.log(client_identify);
    if ( this.form.valid) {

    this.confirmLoading = true;

    try {


      const form = this.form.getRawValue();
    
      
       const {
        description,
        payment_method,
        reference,
        code_identity,
        identity,
        total_amount,
        phone,
        origin_account,
        code_phone,
        accountNumber

      }  = form;

      const formData = new FormData();

      if (this.formDataUpload) {

         formData.append("archivo", this.formDataUpload);
       
     }
     

     let code = this.person?.person?.code;


     let deductions_payment:any = {};
  
     let account = this.account;

    this.deduction_payment.forEach((v:any, key:any) => {
  

      if((v?.currency?.code == account?.currency?.code) || !v?.currency ){
        if(v.isPorcent){
          let resp = v?.amount || 0;
         
  
           let porcent = this.toFixedApp(this.toFixedApp((total_amount * resp) / 100));
   
            deductions_payment[v._id] = porcent;
        }
      }
  
  });


  debugger
  
    if(!this.multipago){

       formData.append("params", JSON.stringify({
        currency:this.account?.currency?.code,
        description,
        payment_method,
        reference,
        cid:`${code_identity}${identity}`,
        total_amount,
        code_person: code,
        code_account_payment : this.account_payment,
        code_installment:this.installment?.code,
        date_register:this.date_base_string,
        deductions_payment: deductions_payment,
        phone:`${code_phone}${phone}`,
        bankCode:origin_account,
        accountNumber,
        client_identify
      
      }));


    }else{

      formData.append("params", JSON.stringify({
        currency:this.account?.currency?.code,
        description,
        payment_method,
        reference,
        cid:`${code_identity}${identity}`,
        total_amount,
        code_person: code,
        code_account_payment : this.account_payment,
        date_register:this.date_base_string,
        deductions_payment: deductions_payment,
        phone:`${code_phone}${phone}`,
        bankCode:origin_account,
        accountNumber,
        client_identify

      }));

    }

    //  throw new Error("");
      

  
     let resp = await this._user.getRegister_payment(formData);

     this.responseTransaction = resp;

     if(this.responseTransaction?.status == 'A'){

       this.confirm = true;

     }
     
  
     
      //  this.ordernumber = resp?.res?.code;

       // alertifyjs.success(`Solicitud de orden de ${this.type} Creada ${this.ordernumber}`);
        this.confirmLoading = false;
        this.selectView = 4;

 
    } catch (error:any) {

      
      if(error.status == 400 && error?.error?.control){

        this.responseTransaction = error?.error;
        this.selectView = 4;


      }else{

        if(error?.error?.code == 52){
          alertifyjs.error(error?.error?.msg);
          this.selectView = 1;

        }else{

          alertifyjs.error(`Error interno, vuelva a cargar el pago`);

        }


      // alertifyjs.error(`Error al crear solicitud `);

      }

      this.confirmLoading = false;

    }
  
  }

  }

  setUpdateOrder = async () => {


    if ( this.form.valid) {

    this.confirmLoading = true;

    try {


      const form = this.form.getRawValue();
    
      
       const {
        description,
        payment_method,
        reference,
        code_identity,
        identity,
        total_amount,
        phone,
        origin_account,
        code_phone,
        accountNumber

      }  = form;

      const formData = new FormData();

      if (this.formDataUpload) {

         formData.append("archivo", this.formDataUpload);
       
     }
     

     let code = this.person?.person?.code;


     let deductions_payment:any = {};
  
     let account = this.account;

    this.deduction_payment.forEach((v:any, key:any) => {
  

      if((v?.currency?.code == account?.currency?.code) || !v?.currency ){
        if(v.isPorcent){
          let resp = v?.amount || 0;
         
  
           let porcent = this.toFixedApp(this.toFixedApp((total_amount * resp) / 100));
   
            deductions_payment[v._id] = porcent;
        }
      }
  
  });
  
       formData.append("params", JSON.stringify({
        currency:this.account?.currency?.code,
        description,
        payment_method,
        reference,
        cid:`${code_identity}${identity}`,
        total_amount,
        code_person: code,
        code_account_payment : this.account_payment,
        code_installment:this.installment?.code,
        date_register:this.date_base_string,
        deductions_payment: deductions_payment,
        phone:`${code_phone}${phone}`,
        bankCode:origin_account,
        accountNumber,
        code_payment:this.paymentEdit?.payment?.code || null,
        description_edit: this.paymentEdit?.description_edit


      }));

    //  throw new Error("");
      

   

     let resp = await this._user.getUpdate_payment(formData);


     this.responseTransaction = resp?.resp;
 
        this.confirmLoading = false;
        this.selectView = 4;

 
    } catch (error:any) {

      
      if(error.status == 400 && error?.error?.control){

        this.responseTransaction = error?.error;
        this.selectView = 4;


      }else{

        if(error?.error?.code == 52){
          alertifyjs.error(error?.error?.msg);
          this.selectView = 1;

        }else{

          alertifyjs.error(`Error interno, vuelva a cargar el pago`);

        }


      // alertifyjs.error(`Error al crear solicitud `);

      }

      this.confirmLoading = false;

    }
  
  }

  }

  updateValueCurrency(value: any) {

    return this.currencyPipe.transform(value, 'USD', '', '1.2-2');
}




onFileSelected  = async  (event:any) => {
  const file:File = event.target.files[0];
/*
  const fileSizeInBytes = file.size;
  const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
  
  console.log(`El tamaño del archivo es de ${fileSizeInMB.toFixed(2)} MB`);
*/

  if (file) {
      this.fileName = file.name;
      this.formDataUpload = file;

     /* const formData = new FormData();

      formData.append("archivo", file);
      
      formData.append("params", JSON.stringify({
         nombre:"juan soto"
      })

      
    );*/
     // const resp = await this._LaService.setUpload(formData);

  }


}



getIdentity(item: any) {

  if (!(item?.identitys?.length > 0))
    return "";

  return `${item?.identitys[0]?.type_identity?.name} ${item?.identitys[0]?.code_identity?.name}-${item?.identitys[0]?.identity}`;

}


getContact(item: any) {

  if (!(item?.constacts?.length > 0))
    return "";

  return `${item?.constacts[0]?.contact}`;

}


sanitize(url: string) {
  //return url;
  return this.sanitizer.bypassSecurityTrustUrl(url);
}


getPersonInstallmentsInvoiceCredits = async (invoice:any) => {
  this.loading = true;
  try {
       const data =  {
        code_invoice:invoice
       
       }

     const  installments  = await this._user.getPersonInstallmentsInvoiceCredits(data);

     installments?.res?.map((value:any, index:any) => {

      if(value?.installMent?.code == this.installmentParams){
   
        this.installment = value?.installMent;
        this.dueBalance =  this.toFixedApp(value?.installMent?.invoice?.dueBalance);
        this.dueBalanceCurrecy = this.dueBalance;

        this.payments = value?.payments;
        this.paymentsReference = value?.paymentReference;

      }



      return value;
    });

    
    if(!this.installment){
      this.router.navigate(['/'], {});

    }



  let remanente_resp =  await this._user.getRemanentInvoice({
    "invoiceCode": invoice,
    "status": true
   })


   let remanente: any;
   remanente_resp?.map((value2:any)=>{

     remanente = {};

      remanente['payment_status_remante'] = true;
      remanente['remaining_amount'] =  value2?.remaining_amount;
      remanente['total_amount_next_installment'] =  value2?.total_amount_next_installment;
    });

  this.remanente = remanente;

  if(remanente){
    this.installment.dueBalance = remanente.total_amount_next_installment;

  }


    this.getPerson(this.installment?.invoice?.customer?.code);

    this.paymentsReference?.map((value:any, index:any) => {

      this.totalpaymentReference = this.totalpaymentReference + value?.payment?.amount;
      return value;
      
    });


    this.amountFB =  this.toFixedApp((this.installment?.dueBalance - this.totalpaymentReference)) ;
    this.amountFB_bs  = this.toFixedApp(this.amountFB * this.rate.amount)
    this.amountF =  0 ;

    } catch (error) {
      console.error(error);
     // this.router.navigate(['/'], {});

    }

    this.loading = false;

}

changeMount(){
  let value = this.toFixedApp(this.form?.getRawValue()?.total_amount) ;

  this.setAmount();

  let f = this.amountFB;

  let account = this.account;

  this.dueBalanceCurrecy = this.toFixedApp(this.toFixedApp(this.dueBalance) +  this.toFixedApp(10));

  
  if(account?.currency?.code == "BS"){


    this.dueBalanceCurrecy = this.toFixedApp(this.toFixedApp(this.toFixedApp(this.dueBalance) +  this.toFixedApp(10)) * this.toFixedApp(this.rate.amount)); 


      f  = this.toFixedApp(this.amountFB * this.rate.amount);
      this.amountFB_bs  = this.toFixedApp(this.amountFB * this.rate.amount);

  }


  this.amountF =   this.toFixedApp(f - (value || 0)) ;


  
/*
  this.dueBalance = (this.installment.dueBalance - value) < 0? 0 :  (this.installment.dueBalance - value) ;
  this.amount = (this.installment.amount + value) > this.installment.amount ? this.installment.amount :  (this.installment.amount + value) ;
  
*/

}

rateConverte(){

  let value = this.form?.getRawValue()?.total_amount;
  if(this.account?.currency?.code == "BS"){

   this.toFixedApp(value = value / this.rate?.amount) ;

  }

  return value;

}



openview(content: TemplateRef<any>) {

  this.offcanvasService.open(content, { position: 'start', keyboard: false });

}

getAccountPayment = async () =>  {
  console.log('cambia');
  this.accountPayment_load = true;

  try {
    let resp =  await this._user.account_payment();

    this.accountPayment = resp?.res;
    this.accountPayment_load = false;
  
  } catch (error) {
    console.log(error);
    this.accountPayment_load = false;

    
  }

}


selectAccount(account:any){

  this.account_payment = account?.code;
  this.account = account;
  // console.log(this.account);
  // if(this.account?.reference_length) {
  //   this.reference_length = this.account?.reference_length;
  // } else {
  //   this.reference_length = 6;
  // }

  if(!this.paymentEdit){
    
    this.form.get('total_amount').setValue('');
    this.form.get('total_amount').patchValue('');
  
    this.changeMount();

  }

  this.offcanvasService.dismiss();
  
}


selectAccount2(account:any){

    this.account_payment = account?.code;
    this.account = account;
  }

getRate = async (date= null,init=false,changeMount =false) => {
  try {
    this.rate_error = false;

    this.rate_load = true;
    
    let data:any =  { "subscriptor":1};

    if(date){
          data = { date,subscriptor:1}
      }

      try {
        const  respose  = await this._user.getRate(data);
        if(!respose)
        this.rate_error = true;

        let rate = respose?.res;
        
        this.rate = rate;
        if(changeMount){

          this.changeMount();

        }
        this.rate_load = false;
                
        if(init){
          this.getPersonInstallmentsInvoiceCredits(this.invoiceParams);

        }

      } catch (error) {
        console.log(error);
        this.rate_error = true;
        this.rate_load = false;

      }

 
  } catch (error) {
    console.log(error);
  }
  
};


 toFixedApp = (x:any) => {

  let a:any = `e+${2}`;
  let b:any = `e-${2}`;

  return +(Math.round(x + a) + b);

  //return Number(Number.parseFloat(String((x + Number.EPSILON))).toFixed(TOFIXED));
  
}


pec = () => {
  return (this.amountF) < 0;
}

pexe = () => {
  let result =  this.amountF  ;
  return result;
}


dateSet(e: any) {

  let date_base =  e.valueText;
  
  let  temp = date_base? date_base.split("/") : [];
  this.date_base_string = `${temp[1]}/${temp[0]}/${temp[2]}`;

  this.getRate(this.date_base_string,false,true);
}



setAmount() {
  let amountFB =  this.toFixedApp((this.installment?.dueBalance - this.totalpaymentReference)) ;

  let account = this.account;
  this.deduction_payment.forEach((v:any, key:any) => {

    if((v?.currency?.code == account?.currency?.code) || !v?.currency ){
      if(v.isPorcent){
        let resp = v?.amount || 0;
       

         let porcent = this.toFixedApp(this.toFixedApp((amountFB * resp) / 100));
 
          amountFB = this.toFixedApp(amountFB + porcent);
      }
    }


});

this.amountFB = amountFB;
  
}

notamounmax(){
  const form = this.form.getRawValue().total_amount;

}

gotoWhatsapp(num:any) : void {
  
  window.open(" https://wa.me/"+num?.replace('+', ''), "_blank");
}


getBanks = async () => {
  try {

    const resp = await this.getBanksServer();

    this.banks =   resp.res.filter((item:any)=>{

        return item.bankCode? true : false;

    })

    
  } catch (error) {
    console.error(error);

  } 

 


}


getBanksServer = async () => {
  try{
      return this._user.getBanks({});

    } catch (error) {
      console.error(error);
      
      
    }
}

validatePhone = () =>{


  let campo = this.form.controls.phone.value;
   if(this.account?.payment_method?.code == 'PAGO_MOVIL'){
   

     if(campo &&  /^\d+$/.test(campo) && campo.length > 6){


       let valueCampo = campo.substr(0, 7);

       this.form.get('phone').setValue(valueCampo);


     this.validationPhone = true;

   }else{

     this.validationPhone = false;
     

   }
 }



}
validateNumberReference = ( min: any, max: any) =>{
  let campo = this.form.controls.reference.value;
  if(campo.length >= min && campo.length <= max){
    this.validationReference = true;
    // } else if(campo &&  /^\d+$/.test(campo) && campo.length > max){
    //   let valueCampo = campo.substr(0, max);
    //   this.form.get('reference').setValue(valueCampo);
    //   this.validationReference = true;
  } else {
    this.validationReference = false;
  }
    
}

/*validateNumberReference = () =>{


  let campo = this.form.controls.reference.value;

   if(this.account?.bank?.name != 'Zelle'){
   
     if(this.account?.bank?.name != 'Mercantil'){
       if(campo &&  /^\d+$/.test(campo) && campo.length > 5){


         let valueCampo = campo.substr(0, 6);

         this.form.get('reference').setValue(valueCampo);


       this.validationReference = true;

       }else{

         this.validationReference = false;
         

       }
     } else {

      if(this.account?.payment_method?.code !== "PAGO_MOVIL") {
        if(campo &&  /^\d+$/.test(campo) && campo.length > 7){


          let valueCampo = campo.substr(0, 8);
 
          this.form.get('reference').setValue(valueCampo);
 
 
          this.validationReference = true;
 
        }else{
 
          this.validationReference = false;
          
 
        }
      } else {
        if(campo &&  /^\d+$/.test(campo) && campo.length > 5){


          let valueCampo = campo.substr(0, 6);
 
          this.form.get('reference').setValue(valueCampo);
 
 
          this.validationReference = true;
 
        }else{
 
          this.validationReference = false;
          
 
        }
      }


       
     }
 }



}*/




maxDuelbalance(){

  if(!this.paymentEdit){

    return this.form.getRawValue().total_amount > this.dueBalanceCurrecy;

  }else{
    return false;
  }
}

validateAccountNumber = () =>{

  let campo = this.form.controls.accountNumber.value;

   if(this.account?.bank?.name != 'Zelle'){
   
     if(campo &&  /^\d+$/.test(campo) && campo.length > 5){

       let valueCampo = campo.substr(0, 6);

       this.form.get('accountNumber').setValue(valueCampo);


     this.validationAccountNumber = true;

   }else{

     this.validationAccountNumber = false;
     

   }
 }



}

moveCursorToEnd(event: any) {
  const input = event.target;
  input.selectionStart = input.selectionEnd = input.value.length;
}

goBack(): void {
  this.location.back();
}

}


