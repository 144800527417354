


<div class="mt-md-3 p-3">
    <div class="row ">
        <div class=" col-12 ">
        <h5 class="mb-1 text-secondary  mb-4 text-center"> <i class="  fas fa-user"></i> Ordenes de pago</h5>
        </div>
    </div>

   
    <div class="row pb-4">
        <div class=" col-6 col-md-4 ">
            <label>Responsable:</label>
            <select class="form-select form-select-sm" [(ngModel)]="selectedResponsable"  aria-label="Default select example" (change)="filter()">
                <option value="" selected>Todos</option>
                <option *ngFor="let step of steps" [value]="step.code">{{ step.name }}</option>
                <option value="Finalizado" selected>Finalizado</option>

            </select>
        </div>
        <div class=" col-6 col-md-4">
            <label>Estatus:</label>
            <select class="form-select form-select-sm" [(ngModel)]="selectedStatus"  aria-label="Default select example" (change)="filter()">
                <option value="" selected>Todos</option>
                <option value="Completado">Completado</option>
                <option value="Pendiente">Pendiente</option>
                <option value="En borrador">Anulado</option>
              </select>
        </div>

        <div class=" col-12 col-md-4 input-container">
            <label>Fecha:</label>
            <input
                style="background: white"
                id="demo-external"
                (onSet)="filter()"
                [(ngModel)]="selectedRange"
                mbsc-range
                class="form-control form-control-sm"
                [mbsc-options]="dateSetting"
                #externalRange="mobiscroll"
                title="Clear"
              />
              <i class="fas fa-times close-icon" aria-hidden="true" (click)="clearRange()"></i>
             
        </div>
        

    </div>
    <div class="row ">
        <div class=" col-12 ">

            
             <ng-container >
                <ngx-datatable
                class="material"
                [rows]="rows"
                [columns]="columns"
                [columnMode]="'force'"
                [headerHeight]="50"
                [footerHeight]="50"
                [rowHeight]="'auto'"
                [scrollbarH]="true"
                style="width: 100%;">
                
                <ngx-datatable-column *ngFor="let column of columns" [name]="column.prop" [width]="column.width">
                  <ng-template ngx-datatable-cell-template let-row="row">
                    
                    <span *ngIf="column.prop == '#'" >{{row[column.prop]}}</span>
                    <span *ngIf="column.prop == 'orden' "  class="text-primary cursor-pounter"
                    
                    (click)="pocisionBottom3(invoicetp,{ code_invoice:row[column.prop].code_invoice, code_installment: row[column.prop].code_installment,code_person:row[column.prop].code_person})"
                    >{{row[column.prop].order}}</span>

                    <span *ngIf="column.prop == 'revision'">{{row[column.prop]}}</span>

                    <span *ngIf="column.prop == 'archivos' && row[column.prop] > 0">
                        {{row[column.prop]}} 
                        <i class="text-warning far fa-file cursor-pounter"></i>
                        
                    </span>

                    <span *ngIf="column.prop == 'note'"><i class="text-warning far fa-comment-alt cursor-pounter"></i></span>
                    
                    <span *ngIf="column.prop == 'organizacion'"  class="text-primary cursor-pounter" [routerLink]="['/console/person/profile/',row[column.prop]?.code]" >{{row[column.prop]?.name}}</span>
                    <span *ngIf="column.prop == 'proveedor' "  class="text-primary cursor-pounter"[routerLink]="['/console/person/profile/',row[column.prop]?.code]">{{row[column.prop]?.name}}</span>
 
                    <span *ngIf="column.prop == 'hora'">{{row[column.prop]}}</span>
                    <span *ngIf="column.prop == 'fecha'">{{row[column.prop]}}</span>
                    <span *ngIf="column.prop == 'moneda'">{{row[column.prop]}}</span>
                    <div *ngIf="column.prop == 'estatus'">

                        

                        <span class="text-danger" *ngIf="row[column.prop]?.code == 'PaymentTrash'">
                            Aulado
                        </span>

                        <span *ngIf="row[column.prop]?.code != 'PaymentTrash'">
                            {{row[column.prop]?.name}}
                        </span>
                    
                    
                    </div>

                      
                    <span *ngIf="column.prop == 'responsable'">
                        <span  *ngIf="!row[column.prop].name" [ngClass]="{'text-success': !row[column.prop].name }">Finalizado</span>
                        <span  *ngIf="row[column.prop].name">   {{row[column.prop].name}}</span>

                      
                    
                    
                    </span>
                    
                    <span *ngIf="column.prop == 'monto'">{{row[column.prop]}}</span>




                    <!-- 
                    <button *ngIf="column.prop === 'accion'" class="btn btn-primary btn-sm" (click)="responder(row.orden)">Responder</button>
                    -->
                  </ng-template>
                </ngx-datatable-column>
              </ngx-datatable>

            <!--  <pre class="cursor-pounter">{{ invoices | json }}</pre> --> 
              

</ng-container>
</div>
</div>
</div>



<ng-template #invoicetp let-offcanvas>
  <div class="offcanvas-header">
      <div class="container ">
          <div class="row">
              <div class="col-12 col-md-10 offset-md-1 ">
                  <div class="row">
                      <div class="col-10">
                          <div class="text-capitalize h5 text-secondary mt-2 ">
                             Invoice
                   
                          </div>
                      </div>
                      <div class="col-2 text-end pt-2">
                          <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click'); "></button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="offcanvas-body">
      <div class=" container">

          <div class="row">
              <div class=" col-12 col-md-10 offset-md-1">



                  <div class="text-start">




                    
                      <app-contact-order-payment  [code_invoice]="params_invoice.code_invoice" [code_installment]="params_invoice?.code_installment" [code_person]="params_invoice?.code_person"     (resetStatus)="setResetStatus($event)" ></app-contact-order-payment>

                  </div>
              </div>
          </div>

      </div>
  </div>
</ng-template>