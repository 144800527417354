import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-order-stadistic-auth',
  templateUrl: './order-stadistic-auth.component.html',
  styleUrls: ['./order-stadistic-auth.component.css']
})
export class OrderStadisticAuthComponent implements OnInit {


  loading:any;
  shop:any;
  stadistic:any;
  shops:any;
  shops_obj:any;
  stepsObj: any ={};
  stepObjData: any ={};

  steps: any ={};


  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    public socketService: SocketioService
  ) { }



  ngOnInit(): void {
    this.stadisticGet()
    this.getSteps()
  }


  stadisticGet = async () => {
    try {
      this.loading = true;

      const resp = await this.getStadisticServer();

     let  stadistic = resp?.res;

     let stepObj:any = {};

     stadistic?.steps[0]?.steps.map((item:any)=>{
      stepObj[item.step] = item;
     })
     this.stepObjData = stepObj;
      

      this.stadistic = resp?.res;


        
    } catch (error) {
      console.error(error);



    } 
    this.loading = false;

   


  }



  getStadisticServer = async () => {
    try{
        return this._user.orderStadistic({});

      } catch (error) {
        console.error(error);
        
    
      }
  }



  getSteps = async () => {
       

    try {
      
       const  steps  = await this._user.getSteps({});
  
       let steps2 = steps?.resp;

       this.steps =  steps2;
  
       for (const item of steps2) {
  
        this.stepsObj[item.code] = item;
        
       }
  
   
  
  
      } catch (error) {
        console.error(error);
        
        
      }
  
  }
  
  
}
