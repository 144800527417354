<!-- <pre>Buro {{ buro | json }}</pre> -->


<div class="" *ngIf="step == 1">
    <form autocomplete="nope" novalidate [formGroup]="form" (ngSubmit)="submit()" class="text-start">
        <div class="row mt-3">
            <div class="form-group  col-12">

                <div class="form-floating">
                    <select [ngClass]="{'error': form.controls.type_identity.invalid && form.controls.type_identity.dirty}" class="form-select" id="type_identity" formControlName="type_identity" aria-label="Floating label select example">
                        <option  value="">Selecione</option>
                        <option *ngFor="let item of type_identity"  [value]="item.code">{{item.name}}</option>
                    </select>

                    <label for="type_identity">Tipo de Documento</label>
                </div>

                <div class="form-control-feedback help-block" *ngIf="form.get('type_identity').errors">
                    <div *ngIf="form.get('type_identity').errors['required'] && !form.get('type_identity').pristine">
                        Campo requerido
                    </div>
                </div>

            </div>

            <div class="form-group  col-4 mt-3">

                <div class="form-floating">
                    <select [ngClass]="{'error': form.controls.code_identity.invalid && form.controls.code_identity.dirty}" class="form-select" id="code_identity" formControlName="code_identity" aria-label="Floating label select example">
                        <option  value="">Selecione</option>
                        <option *ngFor="let item of code_identity"  [value]="item.code">{{item.name}}</option>
                                 </select>



                    <label for="code_identity">Tipo</label>
                </div>

                <div class="form-control-feedback help-block" *ngIf="form.get('code_identity').errors">
                    <div *ngIf="form.get('code_identity').errors['required'] && !form.get('code_identity').pristine">
                        Campo requerido
                    </div>
                </div>
            </div>

            <div class="form-group  col-8  mt-3">

                <div class="form-floating ">
                    <input type="text" [ngClass]="{'error': form.controls.identity.invalid && form.controls.identity.dirty}" class="form-control text-capitalize" id="identity" formControlName="identity">
                    <label for="identity" class=" label font-weight-normal" style="word-wrap: break-word;">
                      <span >Documento </span>
                 </label>
                </div>

                <div class="form-control-feedback help-block" *ngIf="form.get('identity').errors">
                    <div *ngIf="form.get('identity').errors['required'] && !form.get('identity').pristine">
                        Campo requerido
                    </div>
                    <div *ngIf="form.get('identity').hasError('minlength')  && !form.get('identity').pristine">
                        Mínimo 6 caracteres
                    </div>

                    <div *ngIf="form.get('identity').hasError('maxlength')  && !form.get('identity').pristine">
                        Máximo 20 caracteres
                    </div>

                </div>
            </div>

            <br>
            <div class="form-group  col-12 mt-4 mb-2">
                <div class=" float-end">

                    <button type="submit" [disabled]="!form.valid || submit_disabled" class="btn btn-block btn-primary ">
                                    <span >Consultar</span>
                                    <span *ngIf="submit_disabled">
                                      <i class="fa  fa-spinner fa-spin ms-2"></i>
                                    </span>
                    </button>


                </div>

            </div>

        </div>
    </form>

    <div (click)="close.emit(true)" [routerLink]="['/console/person/profile/',person?.person?.code]" class="alert alert-primary bg-white mt-3" role="alert" *ngIf="person && result">

        {{person?.person?.name}} {{person?.person?.lastName}}

        <button class="btn btn-outline-secondary btn-sm float-end "> Ver perfil </button>
    </div>


</div>

<div class="" *ngIf="step == 2">
    <form autocomplete="nope" novalidate [formGroup]="form2" class="text-start">
        <div class="row mt-3">



            <div class="form-group  col-12 mt-3">
                <div class="form-floating">
                    <select [ngClass]="{'error': form2.controls.type.invalid && form2.controls.type.dirty}" class="form-select" id="type" formControlName="type" aria-label="Floating label select example">
                        <option  value="CLIENT">Cliente</option>
                        <option  value="SUSCRIPTOR">Organización</option>
                        <option  value="PROVIDER">Proveedor</option>
                    </select>
                    <label for="type">Tipo de Contacto</label>
                </div>
        
                <div class="form-control-feedback help-block" *ngIf="form2.get('type').errors">
                    <div *ngIf="form2.get('type').errors['required'] && !form2.get('type').pristine">
                        Campo requerido
                    </div>
                </div>
            </div>
        
        

            <div class="form-group col-12  mt-3">



                <div class="text-secondary text-end fz-12">
                    Eje: ejemplo@email.com
                </div>

                <div class="input-group mb-3">
                    <div class="form-floating ">
                        <input type="text" [ngClass]="{'error': form2.controls.email.invalid && form2.controls.email.dirty}" class="form-control" id="email" formControlName="email" placeholder="name@example.com">
                        <label for="email" class=" label font-weight-normal" style="word-wrap: break-word;">
                            <span >Email</span>
                        </label>
                    </div>
                </div>

                <div class="form-control-feedback help-block" *ngIf="form2.get('email').errors">
                    <div *ngIf="form2.get('email').errors['required'] && !form2.pristine">
                        Campo requerido
                    </div>
                </div>
                <div class="form-control-feedback help-block" *ngIf="form2.get('email').hasError('pattern')  && !form2.pristine">
                    <span>Email invalido</span>
                </div>
            </div>

            <div class="form-group col-12  mt-3">

                <div class="text-secondary text-end fz-12">
                    Eje: 4240000000
                </div>

                <div class="input-group mb-3" >
                    <div class="t58" *ngIf="form2.getRawValue()?.contact != ''">
                        +58

                    </div>

                    <div class="form-floating ">
                        <input type="text" [ngClass]="{'error': form2.controls.contact.invalid && form2.controls.contact.dirty,'t582':form2.getRawValue()?.contact != ''}" class="form-control " id="contact" formControlName="contact" placeholder="name@example.com">
                        <label for="contact" class=" label font-weight-normal" style="word-wrap: break-word;">
                            <span >Teléfono Celular</span>
                        </label>
                    </div>
                    <button *ngIf="form2.getRawValue()?.type == 'CLIENT' " (click)="showValidateTel()"   [disabled]="!form2.valid || submit_disabled" class="btn btn-primary">
                        Validar
                        <span *ngIf="submit_disabled_contact">
                        <i class="fa  fa-spinner fa-spin"></i>
                        </span>
                    </button>
                    <button *ngIf="form2.getRawValue()?.type == 'CLIENT' && !env?.production"  (click)="showValidateTel2()" [disabled]="!form2.valid || submit_disabled" class="btn btn-secondary ml-2">
                        No validar
                    </button>


            


                </div>

                <div class="form-control-feedback help-block" *ngIf="form2.get('contact').errors">
                    <div *ngIf="form2.get('contact').errors['required'] && !form2.pristine">
                        Campo requerido
                    </div>
                </div>
                
                <div class="text-end mt-2">
                    <button *ngIf="form2.getRawValue()?.type != 'CLIENT'"  (click)="showValidateTel2()" [disabled]="!form2.valid || submit_disabled" class="btn btn-primary ml-2">
                        Continuar
                    </button>
                </div>
             




                <div>

                </div>


            </div>
            <!-- <br>
            <div class="form-group  col-12 mt-4 mb-2">
                <button type="submit" [disabled]="!form2.valid || submit_disabled" class="btn btn-block btn-primary float-end ">
                                <span >Continuar</span>
                                <span *ngIf="submit_disabled">
                                  <i class="fa  fa-spinner fa-spin ms-2"></i>
                                </span>
                              </button>
            </div> -->
        </div>
    </form>
</div>

<div class="" *ngIf="step == 3">


    <div class=" mt-2  w-100 text-end">
        <i class="far fa-edit text-primary cursor-pounter" *ngIf="show_data_buro" (click)="show_data_buro = !show_data_buro"></i>
    </div>
    <div class="d-flex mt-2">
        <div class="text-secondary ">
            Documento
        </div>
        <div class="fw-bold fz-14 ms-auto">
            <span class="pe-1">{{person?.type_identity}}.</span> {{person?.code_identity}}-{{person?.identity}} </div>
    </div>

    <div class="d-flex mt-2" *ngFor="let item of person?.contacts">
        <div *ngIf="item?.type_contact == 'PHONE_CELL'" class="text-secondary  ">
            Teléfono
        </div>

        <div *ngIf="item?.type_contact == 'EMAIL'" class="text-secondary  ">
            Email
        </div>

        <div class="fw-bold fz-14 ms-auto">
            {{item?.contact}}
        </div>
    </div>


    <ng-container *ngIf="loading ">
        <div class="w-100 text-center mt-3 ">
            <img src="./assets/public/images/loading.gif " alt=" ">
        </div>
    </ng-container>


    <ng-container *ngIf="show_data_buro && !loading">

        <div class="d-flex mt-2">
            <div class="text-secondary ">
                Primer nombre
            </div>
            <div class="fw-bold fz-14 ms-auto">
                <span class="pe-1">{{buro?.FirstGivenName}}  </span>

            </div>
        </div>
        <div class="d-flex mt-2">
            <div class="text-secondary ">
                Segundo nombre
            </div>
            <div class="fw-bold fz-14 ms-auto">
                <span class="pe-1">{{buro?.FirstSurName}}  </span>

            </div>
        </div>

        <div class="d-flex mt-2">
            <div class="text-secondary ">
                Primer apellido
            </div>
            <div class="fw-bold fz-14 ms-auto">
                <span class="pe-1">{{buro?.SecondSurname}}  </span>

            </div>
        </div>

        <div class="d-flex mt-2">
            <div class="text-secondary ">
                Genero
            </div>
            <div class="fw-bold fz-14 ms-auto">
                <span class="pe-1">{{buro?.Genere == 'M'?'Masculino':'Femenino'}}  </span>

            </div>
        </div>

        <div class="d-flex mt-2">
            <div class="text-secondary ">
                Fecha de nacimiento
            </div>
            <div class="fw-bold fz-14 ms-auto">
                <span class="pe-1">{{date_base_string}}  </span>

            </div>
        </div>

    </ng-container>


    <form *ngIf="!loading" autocomplete="nope" novalidate [formGroup]="form3" class="text-start">
        <div class="row mt-3">

            <div [ngClass]="{'d-none':show_data_buro}" class="form-group col-12 mt-3">

                <div class="form-floating ">
                    <input [disabled]="buro" type="text" [ngClass]="{'error': form3.controls.name.invalid && form3.controls.name.dirty}" class="form-control text-capitalize" id="name" formControlName="name" placeholder="name@example.com">
                    <label for="name" class=" label font-weight-normal" style="word-wrap: break-word;">
                    <span *ngIf="form.getRawValue()?.type_identity != 'RIF'" >Primer nombre</span>
                    <span *ngIf="form.getRawValue()?.type_identity == 'RIF'" >Nombre</span>

                <span class="text-danger ps-1">*</span>
                </label>
                </div>

                <div class="form-control-feedback help-block" *ngIf="form3.get('name').errors">
                    <div *ngIf="form3.get('name').errors['required'] && !form3.get('name').pristine">
                        Campo requerido
                    </div>
                </div>

            </div>

            <div *ngIf="form.getRawValue()?.type_identity != 'RIF'">

            <div [ngClass]="{'d-none':show_data_buro}" class="form-group col-12 mt-3">

                <div class="form-floating ">
                    <input type="text" [ngClass]="{'error': form3.controls.second_name.invalid && form3.controls.second_name.dirty}" class="form-control text-capitalize" id="second_name" formControlName="second_name" placeholder="second_name@example.com">
                    <label for="second_name" class=" label font-weight-normal" style="word-wrap: break-word;">
                <span >Segundo nombre</span>
            </label>
                </div>


            </div>

            <div [ngClass]="{'d-none': show_data_buro}" class="form-group col-12 mt-3">

                <div class="form-floating">
                    <input [disabled]="buro" type="text" [ngClass]="{'error': form3.controls.lastName.invalid && form3.controls.lastName.dirty}" class="form-control text-capitalize" id="lastName" formControlName="lastName" placeholder="name@example.com">
                    <label for="lastName" class=" label font-weight-normal" style="word-wrap: break-word;">
                    <span >Primer apellido</span>
                    <span class="text-danger ps-1">*</span>

                </label>
                </div>

                <div class="form-control-feedback help-block" *ngIf="form3.get('lastName').errors">
                    <div *ngIf="form3.get('lastName').errors['required'] && !form3.get('lastName').pristine">
                        Campo requerido
                    </div>
                </div>

            </div>


            <div [ngClass]="{'d-none': show_data_buro}" class="form-group col-12 mt-3">

                <div class="form-floating">
                    <input type="text" [ngClass]="{'error': form3.controls.second_lastName.invalid && form3.controls.second_lastName.dirty}" class="form-control text-capitalize" id="second_lastName" formControlName="second_lastName" placeholder="second_name@example.com">
                    <label for="second_lastName" class=" label font-weight-normal" style="word-wrap: break-word;">
                <span >Segundo apellido</span>
            </label>
                </div>


            </div>




            <div [ngClass]="{'d-none':show_data_buro}" class="form-group  col-12 mt-3">
                <div class="form-floating">
                    <select [disabled]="buro" [ngClass]="{'error': form3.controls.gender.invalid && form3.controls.gender.dirty}" class="form-select" id="gender" formControlName="gender" aria-label="Floating label select example">
                        <option  value="">Selecione</option>
                        <option  value="F">Femenino</option>
                        <option  value="M">Masculino</option>
                    </select>
                    <label for="gender">Genero</label>
                </div>

                <div class="form-control-feedback help-block" *ngIf="form3.get('gender').errors">
                    <div *ngIf="form3.get('gender').errors['required'] && !form3.get('gender').pristine">
                        Campo requerido
                    </div>
                </div>
            </div>

            <div class="form-group  col-12 mt-3">

                <div class="form-floating">
                    <select [ngClass]="{'error': form3.controls.marital_status.invalid && form3.controls.marital_status.dirty}" class="form-select" id="marital_status" formControlName="marital_status" aria-label="Floating label select example">
                        <option  value="">Selecione</option>
                        <option *ngFor="let marital of marital_status"  [value]="marital?.code"> {{marital?.name}}</option>
                    </select>

                    <label for="marital_status">Estado civil</label>
                </div>

                <div class="form-control-feedback help-block" *ngIf="form3.get('marital_status').errors">
                    <div *ngIf="form3.get('marital_status').errors['required'] && !form3.get('marital_status').pristine">
                        Campo requerido
                    </div>
                </div>

            </div>

        </div>
        </div>
    </form>

    <div *ngIf="form.getRawValue()?.type_identity != 'RIF'">
        <div *ngIf="!buro && !loading" class="d-block d-md-none mt-4">
            <mbsc-date (onSet)="dateSet($event)" [(ngModel)]="date_base" [options]="mobileSettings" placeholder="dd/mm/aaaa">Fecha de nacimiento</mbsc-date>
        </div>
        <div *ngIf="!buro && !loading" class="d-none d-md-block mt-4">
            <mbsc-date (onSet)="dateSet($event)" [(ngModel)]="date_base" [options]="desktopSettings" placeholder="dd/mm/aaaa">Fecha de nacimiento</mbsc-date>
        </div>

    </div>



    <br>
    <div *ngIf=" !loading" class=" col-12 mt-4 mb-2">
        <button (click)="sendPerson()" type="button" [disabled]="!form3.valid || submit_disabled" class="btn btn-block btn-primary ">
                        <span >Continuar</span>
                        <span *ngIf="submit_disabled">
                          <i class="fa  fa-spinner fa-spin ms-2"></i>
                        </span>
        </button>
    </div>
</div>

<div class="" *ngIf="step == 4">

    <app-validate-phone *ngIf="activeValidateTlf" [selected]="activeValidateTlf" [config]="dataValidateTlf" (close)="closeValidateTlf($event)" (setear)="setValidateTlf($event)">
    </app-validate-phone>

</div>

<!-- <pre>{{ form3.getRawValue() | json }}</pre> -->
<!-- <pre>{{ person | json }}</pre> -->