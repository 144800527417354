
import { Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef} from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import * as alertifyjs from 'alertifyjs';

@Component({
  selector: 'app-create-contact',
  templateUrl: './create-contact.component.html',
  styleUrls: ['./create-contact.component.css']
})
export class CreateContactComponent implements OnInit {
  form:any;
  people:any = [];
  loading:any;
  usersActives:any = {};
  socketServiceSub:any;
  submit_disabled:any;
  active_filter:any;

   reload_loading:any;
   rows_number = 0;
   rows_size = 10;
   reload_loading_pluss =true;
   rows:any = [];
   rows_temp:any = [];

    supUse = false;
    shops:any;
    shops_obj:any;
    shop:any;
    shopEjecutive:any;

    sessionUser:any;

    submit_disabled_shop:any;

   @Output() changeView = new EventEmitter<any>();


   search_form: any = '';
   constructor(
    private offcanvasService: NgbOffcanvas,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user:UserService,
    private sanitizer: DomSanitizer,
    public socketService: SocketioService,
    public _auth : AuthService


  ) { }
  ngOnDestroy(): void {

    
  }

  ngOnInit(): void {
    this._auth.auth$.subscribe((rep: any) => {      

      if(rep){
        this.sessionUser = this._auth.sessionUser;
      }
    });

    this.shop = this.sessionUser.shop;

    if(localStorage.getItem('shopEjecutive')){

      this.shopEjecutive = localStorage.getItem('shopEjecutive');
  
    }

    this.getshops();

  }

  openBottom(content: TemplateRef<any>,data:any= {}) {

    this.offcanvasService.open(content, { position: 'bottom',keyboard: false} );

  }

  closeAddClient(){
    this.getPeople();
    this.offcanvasService.dismiss("");
    
  }


  getPeople = async () =>  {
    this.loading = true;

    const resp =  await this._user.getPeople();
    this.people = resp.resp;


    this.nextRows();


    this.loading = false;

  }


  nextRows = () =>{

    this.reload_loading_pluss =true;

this.rows_number = this.rows_number + this.rows_size;

if(this.rows_number > this.people?.lenght){
 this.rows_number = this.people?.lenght;
}

let rows = this.people?.slice(0, this.rows_number);
this.rows = rows;

this.reload_loading_pluss = false;
  
  }

  
  getIdentity(item:any){

    if(!(item?.identitys?.length > 0))
      return "";

      return `${item?.identitys[0]?.type_identity?.name} ${item?.identitys[0]?.code_identity?.name}-${item?.identitys[0]?.identity}`;
  }



sanitize(url: string) {
  //return url;
  return this.sanitizer.bypassSecurityTrustUrl(url);
}
onScrollDown() {
  if(!this.active_filter){

    this.nextRows();

   }

  console.log("scrolled down!!");
}

onScrollUp() {
  
  console.log("scrolled up!!");
}


  onScroll() {

    console.log("scrolled!!");
  }

search_form_method() {

if(this.search_form?.trim() != ''){

  this.active_filter = true;

      this.rows_temp = this.rows_temp?.length > 0? this.rows_temp : this.rows;

      let datas:any = [];
      
       datas =  this.people?.filter((element:any) =>{

        let name =  element?.person?.name;
        let lastName =  element?.person?.lastName;
        let ename =  element?.person?.ejecutive?.name;
        let elastName =  element?.person?.ejecutive?.lastName;
        let identity = this.getIdentity(element)

      
        let resp = 
        identity.toLowerCase().includes(this.search_form) ||
         name.toString().toLowerCase().indexOf(this.search_form.toLowerCase()) !== -1 ||
         lastName.toString().toLowerCase().indexOf(this.search_form.toLowerCase()) !== -1;
        //  ename.toString().toLowerCase().indexOf(this.search_form.toLowerCase()) !== -1 ||
        //  elastName.toString().toLowerCase().indexOf(this.search_form.toLowerCase()) !== -1;
         

        return resp;
      });
      



      this.rows= datas;


      }else{
             

            this.rows = this.rows_temp?.length > 0? this.rows_temp : this.rows;
            this.active_filter = false;

      }

   }

/*
external(){
  let url = `/console/constacts`;
  window.open(url, '_blank');
}*/


getshops = async () => {
  try {

    const resp = await this.getshopsServer();
    this.shops = resp?.res;

    let shops_resp = resp?.res;
    let shops:any = {};


    shops_resp.map((value:any)=>{

      shops[value.code] = value?.name;

    });

    this.shops_obj = shops;
    
    
  } catch (error) {
    console.error(error);

  } 

 


}


getshopsServer = async () => {
  try{
      return this._user.getshops({});

    } catch (error) {
      console.error(error);
      
      
    }
}



changeShop = async () => {
    
  try {
    let query = {
      id_user: this.sessionUser.uid,
      code_shop:this.shop
    };


     await this._user.shop_user(query);

     localStorage.setItem('shopEjecutive',this.shop);
     this.shopEjecutive = this.shop;

    alertifyjs.success('Asignación de tienda exitosa');

  } catch (error) {

    alertifyjs.error('Error al cambiar tienda');
    console.log(error);
    
  }


}


}
