import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-financal-dashboard',
  templateUrl: './financal-dashboard.component.html',
  styleUrls: ['./financal-dashboard.component.css']
})
export class FinancalDashboardComponent implements OnInit {

  constructor(
    public _auth : AuthService

  ) { }

  ngOnInit(): void {
  }

}
