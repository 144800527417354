
import { DatePipe } from '@angular/common';
import { noUndefined } from '@angular/compiler/src/util';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { async } from '@angular/core/testing';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';
import { mobiscroll, MbscDatetimeOptions, MbscFormOptions } from '@mobiscroll/angular';

import * as alertifyjs from 'alertifyjs';
import { SocketioService } from 'src/app/services/socket/socketio.service';

import Swal from'sweetalert2';
import { AuthService } from 'src/app/services/auth/auth.service';

mobiscroll.settings = {
  lang: 'es',
  theme: 'ios',
  themeVariant: 'light',
};


const now = new Date();

//const week = [ new Date(now.getFullYear(),0, 1),now];
let week = [ new Date() ,new Date()];

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css'],
  providers: [DatePipe]

})



export class PaymentsComponent implements OnInit {

  @Input() code_person: any;
  @Input() code_invoice: any;
  @Input() code_installment: any;
  @Output() resetStatus = new EventEmitter<any>();
  
  queryParams: any;
  person: any;
  section_transactions: any = [];
  transaction: any = {};
  section_transaction: any;
  loading_person = false;
  loading = false;

  buro_response: any;
  buro: any;
  buro_score: any;
  activatePsico: any;
  showActivatePsico: any;

  loading_buro = false;
  psicometrico: any;
  psicometrico_response: any;
  psicometrico_array: any = [];
  status_approved: any;
  type_approved: any;
  clientQuota:any;
  invoiceCredits: any = [];
  resp_paymentsCredits: any = [];
  installments: any = [];
    view = 1;
    invoiceSelected:any;
    installMentSelected:any;
    payment:any;
    loadcon:any;
    loadcon2:any;
    
    filter:any= {
      type:'p',
      account:'',
      sub_status:'NA'
    }

    accountPayment_load:any;
    accounts:any;

    autopago: any = [{key: "BA", name: "AUTO: BANCAAMIGA"}, {key: "MERCHANT", name: "AUTO: MEGASOFT"}, {key: "MC", name: "AUTO: MERCANTIL"}];

   page = 1; // Current page number
   limit = 100; // Number of documents per page

   range: Array < Date > = week;
   date: Array < Date > = [];
   nonForm: Array < Date > = [];
   external = week;
   d1:any;
   d2:any; 
   subStatus:any = {};
  submit_disabled_changeSubStatus: any;
  list_payment_sub_status:any;
 
  
   dateSetting3: MbscDatetimeOptions = {
     lang: 'es',
     theme: 'ios',
     themeVariant: 'light',
     max:now,
     responsive: {
       small: {
           controls: ['calendar'],
           display: 'center'
       },
       medium: {
           display: 'bubble',
       }
   }
   
   };
  
   birthday = new Date();
  date_base: any = new Date;
  date_base_string :any;
  banks :any;

  
  mobileSettings: MbscDatetimeOptions = {
   display: 'bottom',
   themeVariant: 'dark',
  };
  
  
  desktopSettings: MbscDatetimeOptions = {
   display: 'bubble',
   touchUi: false
  };

  type: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,
    private datePipe: DatePipe,
    public socketService: SocketioService,
    public _auth : AuthService



  ) { }

  ngOnInit(): void {
    

    this.activatedRoute.params.subscribe(params => {
      this.queryParams = params;
      let type;
      if (this.queryParams?.type) {
        this.type = this.queryParams?.type;
      }

      this.getBanks();
      this.d1 = this.datePipe.transform(this.range[0], 'MM/dd/yyyy');
      this.d2 = this.datePipe.transform(this.range[1], 'MM/dd/yyyy');

        
      this.loading_person = true;
      this.getPayments(this.type);
      this.getAccountPayment();
      this.getpayment_sub_status();

    });

    



  }

  getPayments = async (type: any) => {
    try {
      this.loading_person = true;

      const resp = await this.listPaymentsCredits(type);
      let result = resp?.res;

      let resp_paymentsCredits:any = this.resp_paymentsCredits;


      let temp = resp?.res;

      temp?.forEach((element:any) => {
        

          this.subStatus[element.payment.code]  = element?.payment?.payment_sub_status?.code || 'NA';

      });
      
      this.resp_paymentsCredits =resp_paymentsCredits.concat(result);

    } catch (error) {
      console.error(error);

    } 

    this.loading_person = false;

  }

  concilidarPago = async (payment:any) => {
    let action = 'Conciliar'

    Swal.fire({
      title: `¿Deseas ${action} este pago?`,
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `Si, ${action}!`
    }).then((result) => {
      if (result.isConfirmed) {
        

        this.concilidarPagoQuestion(payment);

      }
    });

  }


  cancelPayment = async (payment:any) => {

    let action = 'Anular'

    Swal.fire({
      title: "¿Deseas Anular este pago?",
      text: "¡No podrás revertir esto!",
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      input: "select",
      inputOptions: {
        'Referencia errada': 'Referencia errada',
        'Banco errado': 'Banco errado',
        'Soporte errado': 'Soporte errado',
        'Sin soporte': 'Sin soporte',
        'Monto errado': 'Monto errado',
        'Fecha errada': 'Fecha errada',
        'Datos de pago errados': 'Datos de pago errados',
        'No se encuentra en banco': 'No se encuentra en banco',
        'Método de pago errado': 'Método de pago errado',
        'Pago duplicado': 'Pago duplicado'
      },
      inputPlaceholder: 'Selecciona una razón',
      showCancelButton: true,
      confirmButtonText: `Si, ${action}!`,
      showLoaderOnConfirm: true,
      preConfirm: async (reason) => {
        try {
          if (!reason) {
            return Swal.showValidationMessage(`Debe seleccionar una razón de anulación`);
          }
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`);
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        this.cancelPaymentQuestion(payment, result.value);
      }
    });
  }



   concilidarPagoQuestion = async (payment:any) => {
    this.loadcon = true;
    try {
      const resp = await this._user.reconciledPayment({ code_payment: payment });
      const resp_paymentsCredits = await this.listPaymentsCredits(this.type);
      this.resp_paymentsCredits = resp_paymentsCredits?.res;

      if(this.subStatus[payment] == 'REVISION'){
        this.changeSubStatus(payment,'NA')
  
      }  
  
      if(resp?.res){


        this.getPayments(this.type);


      }

      this.loadcon = false;

    } catch (error) {
      console.error(error);

    this.loadcon = false;
  } 

    this.loading_person = false;


  }


  cancelPaymentQuestion = async (payment:any,msg = '') => {

    this.loadcon2 = true;
    try {
      
      const resp = await this._user.cancelPayment({ code_payment: payment,description:msg });

      if(this.subStatus[payment] == 'REVISION'){
        this.changeSubStatus(payment,'NA')
  
      }  

      const resp_paymentsCredits = await this.listPaymentsCredits(this.type);

      
      this.resp_paymentsCredits = resp_paymentsCredits?.res;

      
  
      if(resp?.res){
        this.getPayments(this.type);

        alertifyjs.success(`Pago anulado codigo ${payment}`);

      }
      this.loadcon2 = false;

    } catch (error) {
      console.error(error);

    this.loadcon2 = false;
  } 

 
    this.loading_person = false;


  }


  listPaymentsCredits = async (type: any) => {
    try {
        this.limit = 200; // Number of documents per page
        let body = this.filter
        if(body.type == 'p'){
          this.limit = 200; // Number of documents per page
        }

        let request = {
          page:this.page, 
          limit:this.limit,
          init:this.d1,
          end:this.d2,
          type_payment: type
        }

        return this._user.listPaymentsCredits(body,request);

      } catch (error) {
        console.error(error);
        
        
      }
  }




  getIdentity(item: any) {

    if (!(item?.identitys?.length > 0))
      return "";

    return `${item?.identitys[0]?.type_identity?.name} ${item?.identitys[0]?.code_identity?.name}-${item?.identitys[0]?.identity}`;

  }


  getContact(item: any) {

    if (!(item?.constacts?.length > 0))
      return "";

    return `${item?.constacts[0]?.contact}`;


  }


  sanitize(url: string) {
    //return url;
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }


  redirectIMG(url:String){
    const link = `${url}`;
    window.open(link, '_blank');

}

setFilter(){

  this.page = 1;
  this.resp_paymentsCredits = [];
  this.getPayments(this.type);

}

getAccountPayment = async () =>  {
  
  this.accountPayment_load = true;

try {

 let resp =  await this._user.account_payment();
 this.accounts = resp?.res;

} catch (error) {
 console.log(error);

 
}

this.accountPayment_load = false;


}

nextRows = () =>{

  this.page = this.page + 1 ; 
  this.getPayments(this.type);

}

birthdaySet() {  

  this.resp_paymentsCredits =[];
  this.page =  1 ; 

  this.d1 = this.datePipe.transform(this.range[0], 'MM/dd/yyyy');
  this.d2 = this.datePipe.transform(this.range[1], 'MM/dd/yyyy');
  this.getPayments(this.type);
    
}

findAutopago = (acron: string) => {
  console.log(acron);
  if(acron==="BA" || acron==="MC") {
    let autopago = this.autopago.find((ele: any) => ele.key===acron);
    if(autopago) {
      return autopago.name;
    } else {
      return null;
    }
  } else {
    return acron
  }
}


getBanks = async () => {
  try {
    const resp = await this.getBanksServices();

    let banks_resp = resp?.res;
    let banks:any = {};


    banks_resp.map((value:any)=>{

      banks[value.bankCode] = value?.name;

    });

    this.banks = banks;
    
    
  } catch (error) {
    console.error(error);

  } 

}


getBanksServices = async () => {
  return await this._user.getBanks({});
};


setPayment(data:any){


  let action = 'Editar'

  Swal.fire({
    title: `¿Deseas ${action} este pago?`,
    text: "¡No podrás revertir esto!",
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    input: "select",
    inputOptions: {
      'Banco Receptor errado': 'Banco Receptor errado',
      'Banco Emisor errado': 'Banco Emisor errado',
      'Número de referencia errado': 'Número de referencia errado',
      'Fecha de Pago errada': 'Fecha de Pago errada',
      'Monto errado': 'Monto errado',
      'Comprobante de pago errado': 'Comprobante de pago errado'
    },
    inputPlaceholder: 'Selecciona una razón',
    showCancelButton: true,
    confirmButtonText: `Si, ${action}!`,
    showLoaderOnConfirm: true,
    preConfirm: async (reason) => {
      try {
        if (!reason) {
          return Swal.showValidationMessage(`Debe seleccionar una razón de Edición`);
        }
      } catch (error) {
        Swal.showValidationMessage(`Request failed: ${error}`);
      }
    },
    allowOutsideClick: () => !Swal.isLoading()
  }).then((result) => {
    if (result.isConfirmed) {
      debugger;
      data.description_edit = result?.value;
      localStorage.setItem('pe',JSON.stringify(data));
      this.router.navigate([`/console/post`], {});
    

    }
  });




}


changeSubStatus= async (code:any,code_sub:any) => {
  this.submit_disabled_changeSubStatus = true;

  
        let query = {
          code_payment: code,
          code_payment_sub_status:code_sub
        };
    
         await this._user.payment_sub_statusPost(query);
         this.subStatus[code] = code_sub;
        alertifyjs.success('Cambio exitoso');
        this.submit_disabled_changeSubStatus = false;
    
    
    

}
  

getpayment_sub_status = async () => {
try {

const resp = await this.payment_sub_status();
this.list_payment_sub_status = resp?.resp;

} catch (error) {
console.error(error);

} 




}

payment_sub_status = async () => {
try{
  return this._user.payment_sub_status({});

} catch (error) {
  console.error(error);
  
  
}
}


paymentRollback = async (payment:any) => {

  let action = 'Revertir'

  Swal.fire({
    title: `¿Deseas ${action} este pago?`,
    text: "¡No podrás revertir esto!",
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    input: "select",
    inputOptions: {

      'No se encontro en banco': 'No se encontro en banco'
     
    },
    inputPlaceholder: 'Selecciona una razón',
    showCancelButton: true,
    confirmButtonText: `Si, ${action}!`,
    showLoaderOnConfirm: true,
    preConfirm: async (reason) => {
      try {
        if (!reason) {
          return Swal.showValidationMessage(`Debe seleccionar una razón`);
        }
      } catch (error) {
        Swal.showValidationMessage(`Request failed: ${error}`);
      }
    },
    allowOutsideClick: () => !Swal.isLoading()
  }).then((result) => {

    if (result.isConfirmed) {

      this.servicePaymentRollback(payment, result.value);

    }
  });
}


  servicePaymentRollback = async (payment:any,msg = '') => {

    this.loadcon2 = true;

    try {
      
      const resp = await this._user.paymentRollback({ code_payment: payment,description:msg });

      const resp_paymentsCredits = await this.listPaymentsCredits(this.type);
      this.resp_paymentsCredits = resp_paymentsCredits?.res;

      
  
      if(resp?.res){
        this.getPayments(this.type);

        alertifyjs.success(`Pago devuelto `);

      }
      this.loadcon2 = false;

    } catch (error) {
      alertifyjs.error('Error al devolver pago estatus');

      console.error(error);

    this.loadcon2 = false;
  } 

 
    this.loading_person = false;


  }



}
