
import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import { UserService } from 'src/app/services/user/user.service';


  @Component({
    selector: 'app-invoice-order-profile',
    templateUrl: './invoice-order-profile.component.html',
    styleUrls: ['./invoice-order-profile.component.css'],
    providers: [DatePipe]

  })
  export class InvoiceOrderProfileComponent implements OnInit {
  
  loading:any;
  invoices:any = [];

  params_invoice:any;


  orders:any = [];
  columns:any = [];
  rows:any = [];

  steps: any =[];
  stepsObj: any ={};

  constructor( 
       private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,
    private datePipe: DatePipe,
    public socketService: SocketioService,
    private commonService: CommonService,
    private offcanvasService: NgbOffcanvas,

    public _auth : AuthService) { }

  ngOnInit(): void {



  
  this.rows = [ ];
  
  // Definición de columnas
this.columns = [
    { prop: '#', name: '#', minWidth: 20, width: 20 },
    { prop: 'orden', name: 'Orden', minWidth: 200 },
    { prop: 'archivos', name: 'Archivos', minWidth: 40, width: 40 },
    { prop: 'note', name: 'Notas', minWidth: 40, width: 40 },
    { prop: 'proveedor', name: 'Proveedor', minWidth: 200},
    { prop: 'fecha', name: 'Fecha', minWidth: 60, width: 60 },
    { prop: 'hora', name: 'Hora', minWidth: 60, width: 60 },
    { prop: 'moneda', name: 'Moneda', minWidth: 50, width: 50 },
    { prop: 'monto', name: 'Monto', minWidth: 60 }, // Ajusta el ancho aquí
    { prop: 'estado', name: 'Estado', minWidth: 250 },
];

  
this.socketService.reload_order.subscribe((resp: any) => {

    
  this.listClientInvoiceCredits();


});

this.getSteps();

  }


  listClientInvoiceCredits = async () => {
       
    try {
      this.loading = true

         const data =  {
          status_code:['PaymentPending','PaymentComplete']
         }

        const invoice_resp =  await  this._user.getInvoiceCreditsDashboardPost(data);
 this.invoices = invoice_resp?.res;
 
        let i = 1;
    let rows:any = [];


    invoice_resp?.res?.forEach((obj: any) => {
      const fechaHoraLocal = new Date(obj?.date);
      // Restar 4 horas para ajustar a Venezuela
        //fechaHoraLocal.setHours(fechaHoraLocal.getHours() - 4);
        
        // Formatear la nueva fecha y hora en formato legible
        const fechaCompleta = fechaHoraLocal.toLocaleString('es-VE', {timeZone: 'America/Caracas'});
        
        const horaCompleta = fechaCompleta.split(" ")[1].split(":").slice(0, 2).join(":");
        const hora = horaCompleta.length === 5 ? horaCompleta + " AM" : horaCompleta.slice(0, 5) + " PM";

        const fechaArr = fechaCompleta.split(",")[0].split("/");

        const dia = fechaArr[0].padStart(2, '0'); // Agrega ceros a la izquierda si es necesario
        const mes = fechaArr[1].padStart(2, '0'); // Agrega ceros a la izquierda si es necesario
        const anio = fechaArr[2];
        const fechaFormatoDMY = dia + "-" + mes + "-" + anio;


        obj?.installments?.forEach((obj2: any) => {

          if (obj2?.order > 0  &&  this.stepsObj[obj2?.workflow?.current?.step]?.code == 'PAYMENT_AUTHORIZATION') {

            let temp =   
            {
              '#': i,
              fecha:  obj2?.date ,
              orden:  {order:`${obj?.identifier}-${obj2?.order}`, code_invoice:obj?.code,code_installment:obj2?.code,code_person:obj?.customer?.code} ,
              archivos: obj?.invoice_file ? `${obj?.invoice_file?.length}` : '',
              note: '',
              hora: hora,
              proveedor: obj?.audience?.obj == 'LEGAL' ? obj.customer?.name : `${obj?.customer?.name} ${obj?.customer?.lastName}`,
              suscriptior: obj?.customer_suscriptor?.obj == 'LEGAL' ? obj.customer?.name : `${obj?.customer?.name} ${obj?.customer?.lastName}`,

              moneda: 'USD',
              description: obj?.description,

              estado: this.stepsObj[obj2?.workflow?.current?.step]?.name,
              monto: obj2?.amount,

              };
 
              rows.push(temp);
              i++;
            
          }


            }); 
      });                                                                                                                                                                                                                                                           


      this.rows = rows;

      this.loading = false
      } catch (error) {
        console.error(error);
        
        
      }
  }
  responder = async (code:any) => {

  
}


getSteps = async () => {
       

  try {
    
     const  steps  = await this._user.getSteps({});

     this.steps = steps?.resp;

     for (const item of this.steps) {

      this.stepsObj[item.code] = item;
      
     }


    } catch (error) {
      console.error(error);
      
      
    }

}


setResetStatus(data:any){

  this.listClientInvoiceCredits();
  
}


pocisionBottom3= async (content: TemplateRef<any>,params_invoice:any) => {
  try {
    debugger

    this.params_invoice = params_invoice;

    this.offcanvasService.open(content, {
      position: 'bottom',
      keyboard: false,

    });
    

  } catch (error) {
    console.log(error);
  }
};


}
