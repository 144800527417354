<div class="container">

    <div class="mt-md-3 p-3">

        <h5 class="mb-1 text-secondary  mb-4 text-center"> Finanzas
        </h5>

        <div class="row ">
            <div class=" col-12 col-md-6 offset-md-3  p-0 ">

                <div class="mt-3 ">

                    <div class="col-12  mt-4" *ngIf="_auth.IsPermitid(['OWNER','FINANCIAL'])">
                        <div class="card" [routerLink]="['/console/dashboard-direct-debit']">
                            <div class="card-body">
                                <h5 class="card-title">Domiciliaciones Bancos
                                    <i class="float-end pt-2  fa fa-credit-card" ></i>


                                </h5>

                            </div>
                        </div>
                    </div>

                    <div class="col-12  mt-4" *ngIf="_auth.IsPermitid(['OWNER','FINANCIAL'])">
                        <div class="card" [routerLink]="['/console/dashboard-direct-debit-alliances']">
                            <div class="card-body">
                                <h5 class="card-title">Domiciliaciones Alianzas Comerciales
                                    <i class="float-end pt-2  fa fa-credit-card" ></i>


                                </h5>

                            </div>
                        </div>
                    </div>

                    <div class="col-12  mt-4">
                        <div class="card" [routerLink]="['/console/invoice-dashboard']">
                            <div class="card-body">
                                <h5 class="card-title">Compras
                                    <i class="float-end pt-2  fas fa-piggy-bank"></i>

                                </h5>

                            </div>
                        </div>
                    </div>

                    <div class="col-12  mt-4">
                        <div class="card" [routerLink]="['/console/installments-console']">
                            <div class="card-body">
                                <h5 class="card-title">Cuotas
                                    <i class="float-end pt-2  fas fa-tablet-alt"></i>

                                </h5>

                            </div>
                        </div>
                    </div>

                    <div class="col-12  mt-4">
                        <div class="card" [routerLink]="['/console/payments']">
                            <div class="card-body">
                                <h5 class="card-title">Pagos
                                    <i class="float-end pt-2  fas fa-money-bill"></i>

                                </h5>

                            </div>
                        </div>
                    </div>
                    <div class="col-12  mt-4">
                        <div class="card" [routerLink]="['/console/credit-calculator']">
                            <div class="card-body">
                                <h5 class="card-title">Calculadora
                                    <i class="float-end pt-2  fas fa-calculator"></i>
                                </h5>
                            </div>
                        </div>
                    </div>

                    <div class="col-12  mt-4">
                        <div class="card" [routerLink]="['/console/export']">
                            <div class="card-body">
                                <h5 class="card-title">Exportables
                                    <i class="float-end pt-2  fas fa-file-export"></i>
                                </h5>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>